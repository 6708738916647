import SearchFilter from '@/components/Filters/SearchFilter';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Scroll from '@radix-ui/react-scroll-area';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';

interface MultiSelectDropdownMenuProps {
  label: string;
  options: { id: string; label: React.ReactNode }[];
  selectedItems: string[];
  onSelectionChange: (selectedIds: string[]) => void;
  buttonClassName?: string;
  itemsClassName?: string;
  scrollAreaClassName?: string;
  buttonContent?: (selectedItems: string[]) => ReactNode;
  showSearch?: boolean;
}

export default function MultiSelectDropdownMenu({
  label,
  options,
  selectedItems,
  onSelectionChange,
  buttonClassName = '',
  itemsClassName = '',
  scrollAreaClassName = '',
  buttonContent,
  showSearch = false,
}: MultiSelectDropdownMenuProps) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    showSearch ? '' : undefined,
  );

  const filteredOptions =
    searchQuery && searchQuery.length > 0
      ? options.filter((option) =>
          String(option.label)
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
        )
      : options;

  const toggleSelection = (id: string) => {
    if (selectedItems.includes(id)) {
      onSelectionChange(selectedItems.filter((item) => item !== id));
    } else {
      onSelectionChange([...selectedItems, id]);
    }
  };

  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35, // Adjust this based on your item height
    overscan: 10,
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      rowVirtualizer.measure();
      rowVirtualizer.getVirtualItems();
    }
  }, [isOpen]);

  const optionsList = (
    <Scroll.Root className="h-full w-full overflow-hidden rounded">
      <Scroll.Viewport
        ref={parentRef}
        className="h-full min-h-[300px] w-full rounded"
      >
        <div className="relative w-full">
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const option = filteredOptions[virtualItem.index];
            return (
              <Menu.Item
                as="div"
                key={option.id}
                className="absolute left-0 top-0 w-full"
                style={{ transform: `translateY(${virtualItem.start}px)` }}
              >
                {({ active }) => (
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      toggleSelection(option.id);
                    }}
                    className={`flex w-full items-center px-4 py-2 text-sm ${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(option.id)}
                      readOnly
                      className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600"
                    />
                    <span className="flex-1 truncate text-left">
                      {option.label}
                    </span>
                  </button>
                )}
              </Menu.Item>
            );
          })}
        </div>
      </Scroll.Viewport>
      <Scroll.Scrollbar
        className="flex touch-none select-none bg-gray-200/10 p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col hover:bg-gray-200"
        orientation="vertical"
      >
        <Scroll.Thumb className="relative flex-1 rounded-[10px] bg-gray-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
      </Scroll.Scrollbar>
      <Scroll.Corner className="bg-black" />
    </Scroll.Root>
  );

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button
            onClick={() => {
              setIsOpen(!open);
            }}
            className={`group inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 ${buttonClassName}`}
          >
            {buttonContent ? (
              buttonContent(selectedItems)
            ) : (
              <>
                {label}
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </>
            )}
          </Menu.Button>

          {options.length > 0 && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${itemsClassName}`}
              >
                {showSearch && (
                  <div className="p-2">
                    <SearchFilter
                      query={searchQuery ?? ''}
                      setQuery={setSearchQuery}
                    />
                  </div>
                )}

                <div className={scrollAreaClassName}>{optionsList}</div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  );
}
