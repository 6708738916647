import { askQuestion } from '@/api/chat';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

export const useChat = () => {
  const [answer, setAnswer] = useState<string | null>(null);

  const {
    mutateAsync: fetchAnswer,
    error: fetchError,
    isPending: isFetching,
  } = useMutation<
    { answer: string }, // Expected data type
    Error, // Error type
    { context: string; question: string } // Variables type
  >({
    mutationFn: async ({ context, question }) => {
      return await askQuestion(context, question);
    },
    onSuccess: (data) => {
      setAnswer(data.answer);
    },
    onError: (error) => {
      console.error('wowo', error);
    },
  });

  return {
    answer,
    fetchError,
    isFetching,
    fetchAnswer,
  };
};
