import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Define the shape of the auth context
interface AuthContextType {
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Auth provider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { getCurrentUser } = useAuth();

  const handleNoAuth = () => {
    if (
      location.pathname !== navigationMap.forgotPassword.href &&
      location.pathname !== navigationMap.resetPassword.href &&
      location.pathname !== navigationMap.register.href &&
      location.pathname !== navigationMap.login.href
    ) {
      navigate(navigationMap.login.href);
    }
  };

  // Check authentication status using useAuth hook
  useEffect(() => {
    async function checkAuthStatus() {
      try {
        const user = await getCurrentUser();
        setIsAuthenticated(!!user);
        if (!user) {
          handleNoAuth();
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setIsAuthenticated(false);
        handleNoAuth();
      }
    }
    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error('useAuthContext must be used within an AuthProvider');
  return context;
};
