import classNames from 'classnames';
import React from 'react';

type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';
type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'ghost' | 'outline';

type ButtonProps = {
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: React.ComponentType<{ className?: string }>;
  iconPosition?: 'left' | 'right';
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'md',
  icon: Icon,
  iconPosition = 'left',
  fullWidth = false,
  type = 'button',
  className,
  disabled,
  onClick,
  ...props
}) => {
  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2.5 py-1.5 text-sm',
    md: 'px-3 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
  };

  const iconSizeClasses = {
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-5 w-5',
  };

  const variantClasses = {
    primary: 'bg-blue-600 text-white hover:bg-blue-500 shadow-sm',
    secondary: 'bg-gray-200 text-gray-900 hover:bg-gray-300',
    danger: 'bg-red-600 text-white hover:bg-red-500 shadow-sm',
    ghost: 'text-gray-700 hover:bg-gray-50',
    outline:
      'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 shadow-sm',
  };

  const baseClasses = classNames(
    'inline-flex items-center justify-center rounded-md font-medium',
    'transition-colors duration-200',
    'focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-none active:outline-none',
    'disabled:opacity-50 disabled:cursor-not-allowed',
    sizeClasses[size],
    variantClasses[variant],
    fullWidth && 'w-full',
    className,
  );

  const iconClass = classNames(
    iconSizeClasses[size],
    iconPosition === 'left' ? '-ml-0.5 mr-1.5' : '-mr-0.5 ml-1.5',
  );

  return (
    <button
      type={type}
      className={baseClasses}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {Icon && iconPosition === 'left' && <Icon className={iconClass} />}
      {children}
      {Icon && iconPosition === 'right' && <Icon className={iconClass} />}
    </button>
  );
};

export default Button;
