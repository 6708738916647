const SplitPanel = ({
  leftContent,
  rightContent,
  className,
}: {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
  className?: string;
}) => {
  return (
    <div
      className={`flex h-full w-full max-w-full overflow-hidden ${className}`}
    >
      <div className="w-1/2">{leftContent}</div>
      <div className="w-1/2">{rightContent}</div>
    </div>
  );
};

export default SplitPanel;
