import { DocumentSummary, Summary } from '@/types/notification';
import { RagArticleResult } from '@/types/rag';
import { LinkIcon } from '@heroicons/react/20/solid';
import { RagSummaryBulletsSkeleton } from './Skeletons';
import { useEffect, useState } from 'react';
import ScrollContainer from '../List/ScrollContainer';

const RagSummary = ({
  summary,
  onSelectSource,
}: {
  summary: DocumentSummary;
  onSelectSource: (contentId: string | null, sourceId: number) => void;
}) => {
  const detailedSummaries = summary.detailedSummaries;

  return (
    <ScrollContainer>
      <div className="flex w-full flex-col text-sm text-gray-800">
        <div className="w-full text-left">
          <p>{summary?.summary}</p>
        </div>
        {detailedSummaries.length === 0 && <RagSummaryBulletsSkeleton />}
        {detailedSummaries.map((detailedSummary, index) => (
          <div key={index}>
            <ul className="w-full list-disc pl-4 text-left">
              {detailedSummary.bullets.map((bullet, index) => (
                <li key={index} className="w-full py-2 text-left">
                  <span className="w-full text-left">
                    {bullet.bullet}
                    {bullet.citations.map((citation, index) => (
                      <button
                        key={index}
                        className="inline-flex cursor-pointer items-center pl-1.5 text-left"
                        onClick={() => {
                          onSelectSource(
                            citation.contentId,
                            Number(citation.sourceId),
                          );
                        }}
                      >
                        <LinkIcon className="inline-block h-3 w-3 text-gray-500" />
                      </button>
                    ))}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </ScrollContainer>
  );
};

const ArticleSummary = ({
  article,
  getSummary,
  isLoading,
  version,
  onSelectSource,
}: {
  article: RagArticleResult;
  getSummary: (article: RagArticleResult) => Promise<Summary | null>;
  isLoading: boolean;
  version: 'short' | 'long';
  onSelectSource: (contentId: string | null, sourceId: number) => void;
}) => {
  const [summary, setSummary] = useState<Summary | null>(null);
  const longVersion = version === 'long';

  useEffect(() => {
    if (longVersion) {
      getSummary(article).then(setSummary);
    }
  }, [article, longVersion, getSummary]);

  return (
    <div
      className="mb-1 flex w-full cursor-pointer flex-col rounded-lg p-4 text-sm text-gray-800 outline outline-1 outline-gray-200 hover:bg-gray-50"
      onClick={() => onSelectSource(article.contentId, -1)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onSelectSource(article.contentId, -1);
        }
      }}
    >
      <div className="w-full text-left">
        <p>{article?.title}</p>
      </div>
      {longVersion && !isLoading && (
        <ul className="w-full list-disc pl-4 text-left">
          {summary?.bullets.map((bullet, index) => (
            <li key={index} className="w-full py-2 text-left">
              <span className="w-full text-left">
                {bullet.bullet}
                {bullet.citations.map((citation, index) => (
                  <button
                    key={index}
                    className="inline-flex cursor-pointer items-center pl-1.5 text-left"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectSource(
                        article.contentId,
                        Number(citation.sourceId),
                      );
                    }}
                  >
                    <LinkIcon className="inline-block h-3 w-3 text-gray-500" />
                  </button>
                ))}
              </span>
            </li>
          ))}
        </ul>
      )}
      {longVersion && isLoading && <RagSummaryBulletsSkeleton />}
    </div>
  );
};

export { RagSummary, ArticleSummary };
