import { searchArticles, SearchParams, SearchResponse } from '@/api/search';
import { useQuery } from '@tanstack/react-query';

const SEARCH_QUERY_KEY = 'SEARCH';

export function useSearch({ ...filters }: SearchParams) {
  const {
    data: searchData,
    error: searchError,
    isFetching: isFetchingSearch,
  } = useQuery<SearchResponse, Error>({
    queryKey: [SEARCH_QUERY_KEY, filters],
    queryFn: () =>
      searchArticles({
        page: filters.page,
        pageSize: filters.pageSize,
        sort: filters.sort,
        query: filters.query,
        dateRange: filters.dateRange,
        jurisdiction: filters.jurisdiction,
        regulator: filters.regulator,
        regulation: filters.regulation,
        documentType: filters.documentType,
        topic: filters.topic,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    isFetching: isFetchingSearch,
    error: searchError,
    results: searchData?.results,
    totalCount: searchData?.total,
    totalPages:
      searchData?.total && filters.pageSize
        ? Math.ceil(searchData.total / filters.pageSize)
        : 0,
  };
}
