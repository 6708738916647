import posthog from 'posthog-js';
import { useEffect } from 'react';

const usePageVisitTracker = (pageName: string) => {
  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now();
      const duration = endTime - startTime;

      // Track page visit duration
      posthog.capture('page_visit', {
        page: pageName,
        duration, // duration in milliseconds
      });
    };
  }, [pageName]);
};

export default usePageVisitTracker;
