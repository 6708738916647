import ScrollContainer from '@/components/List/ScrollContainer';
import BaseTooltip from '@/components/Utils/BaseTooltip';
import { useProfiles } from '@/hooks/useProfiles';
import { useProfilesContext } from '@/providers/profilesProvider';
import { Profile } from '@/types/profiles';
import { Transition } from '@headlessui/react';
import {
  BookOpenIcon,
  BriefcaseIcon,
  GlobeAltIcon,
  PlusIcon,
  ScaleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import Pill from '../Pill';
import Button from '../Utils/Button';

interface Props {
  onClose: () => void;
  profile?: Profile;
}

const CreateProfileView: React.FC<Props> = ({ onClose, profile }) => {
  const isEditing = !!profile;
  const [profileName, setProfileName] = useState(profile?.name || '');
  const [regulations, setRegulations] = useState(profile?.regulations || ['']);
  const [services, setServices] = useState(profile?.services || ['']);
  const [complianceTopics, setComplianceTopics] = useState(
    profile?.complianceTopics || [''],
  );
  const [jurisdictions, setJurisdictions] = useState(
    profile?.jurisdictions || [''],
  );

  const { createProfile, updateProfile, isLoading } = useProfiles(undefined);
  const { refetchProfiles } = useProfilesContext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const profileData = {
      name: profileName,
      regulations: regulations.filter(Boolean),
      services: services.filter(Boolean),
      complianceTopics: complianceTopics.filter(Boolean),
      jurisdictions: jurisdictions.filter(Boolean),
      data: {},
      profileId: profile?.profileId,
    };
    if (isEditing) {
      await updateProfile({ profileData });
    } else {
      await createProfile({ profileData });
    }
    await refetchProfiles();
    onClose();
  };

  const isFormValid =
    profileName.trim() !== '' &&
    (regulations.some((item) => item.trim() !== '') ||
      services.some((item) => item.trim() !== '') ||
      complianceTopics.some((item) => item.trim() !== '') ||
      jurisdictions.some((item) => item.trim() !== ''));

  return (
    <ScrollContainer>
      <div className="space-y-10 px-6">
        <form onSubmit={handleSubmit}>
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mb-6 flex items-center justify-between pt-2">
              <div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {isEditing ? 'Edit Your Tracker' : 'Setup Your Tracker'}
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  {isEditing
                    ? 'Edit your tracker to monitor specific regulations, services, and compliance topics relevant to your business.'
                    : 'Create a tracker to monitor specific regulations, services, and compliance topics relevant to your business.'}
                </p>
              </div>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="mt-10 max-w-2xl">
              <label
                htmlFor="profileName"
                className="block text-base font-medium text-gray-900"
              >
                Tracker Name
              </label>
              <div className="relative mt-4">
                <input
                  type="text"
                  id="profileName"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  className="block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-3 pl-4 pr-10 text-sm text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
                  placeholder="Enter your tracker name"
                  maxLength={50}
                  style={{
                    outline: 'none',
                    borderColor: 'transparent !important',
                  }}
                  required
                />
                {profileName && (
                  <button
                    type="button"
                    onClick={() => setProfileName('')}
                    className="group absolute right-2 top-1/2 -translate-y-1/2 rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-400 transition-all duration-200 group-hover:text-gray-600" />
                  </button>
                )}
              </div>
            </div>
          </div>

          <ProfileSection
            title="Regulations"
            description="Add specific regulations you want to stay on top of, e.g. PSD2, GDPR, or AMLD6"
            items={regulations}
            onChange={(newItems) => setRegulations(newItems)}
          />
          <ProfileSection
            title="Services"
            description="Add services or products that you want to track related regulatory changes for e.g. payments, lending, or digital assets"
            items={services}
            onChange={(newItems) => setServices(newItems)}
          />
          <ProfileSection
            title="Compliance Topics"
            description="Add relevant compliance topics you want to track e.g. Anti-Money Laundering, Data Protection, or Anti-Bribery"
            items={complianceTopics}
            onChange={(newItems) => setComplianceTopics(newItems)}
          />

          {/* Add similar sections for Services and Compliance Topics */}

          <div className="mt-6 flex items-center justify-end gap-x-2">
            <Button variant="outline" size="sm" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              disabled={isLoading || !isFormValid}
            >
              {isLoading ? 'Saving...' : 'Save Profile'}
            </Button>
          </div>
        </form>
      </div>
    </ScrollContainer>
  );
};

interface ProfileSectionProps {
  title: string;
  description: string;
  items: string[];
  onChange: (newItems: string[]) => void;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  title,
  description,
  items,
  onChange,
}) => {
  const Icon = sectionIcons[title as keyof typeof sectionIcons];
  const [inputValue, setInputValue] = React.useState('');

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      onChange([...items.filter(Boolean), inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (indexToRemove: number) => {
    onChange(items.filter((_, index) => index !== indexToRemove));
  };

  const handleAddItem = () => {
    if (inputValue.trim()) {
      onChange([...items.filter(Boolean), inputValue.trim()]);
      setInputValue('');
    }
  };

  return (
    <div className="mt-8 border-b border-gray-900/10 pb-12">
      <div className="mb-6">
        <div className="flex items-center">
          <span className="mr-3 inline-flex rounded-lg bg-gray-100 p-2 text-gray-700 ring-4 ring-white">
            <Icon className="h-[18px] w-[18px]" aria-hidden="true" />
          </span>
          <h3 className="text-base font-medium text-gray-900">{title}</h3>
        </div>
        <p className="mt-1 text-sm/6 text-gray-600">{description}</p>
      </div>

      <div className="max-w-2xl space-y-4">
        <Transition
          show={items.some(Boolean)}
          enter="transition-all duration-300 ease-out"
          enterFrom="transform -translate-y-4 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-all duration-200 ease-in"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform -translate-y-4 opacity-0"
        >
          <div className="flex flex-wrap gap-2">
            {items.filter(Boolean).map((item, idx) => (
              <Pill
                variant="solid"
                size="sm"
                leftIcon={<XMarkIcon className="h-4 w-4" />}
                key={idx}
                text={item}
                onClick={() => handleRemoveItem(idx)}
              />
            ))}
          </div>
        </Transition>

        <div className="relative">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
            maxLength={50}
            className="block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-3 pl-4 pr-20 text-sm text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
            placeholder={`Add a ${title.slice(0, -1).toLowerCase()}`}
            style={{
              outline: 'none',
              borderColor: 'transparent !important',
            }}
          />
          <div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center gap-2">
            {inputValue.trim() !== '' && (
              <>
                <BaseTooltip tooltipContent="You can also press Enter to add">
                  <button
                    type="button"
                    onClick={handleAddItem}
                    className="group rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
                  >
                    <PlusIcon className="h-5 w-5 text-gray-500 transition-all duration-200 group-hover:text-gray-700" />
                  </button>
                </BaseTooltip>
                <BaseTooltip tooltipContent="Clear input">
                  <button
                    type="button"
                    onClick={() => setInputValue('')}
                    className="group rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-500 transition-all duration-200 group-hover:text-gray-700" />
                  </button>
                </BaseTooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const sectionIcons = {
  Regulations: ScaleIcon,
  Services: BriefcaseIcon,
  'Compliance Topics': BookOpenIcon,
  Jurisdictions: GlobeAltIcon,
};

export default CreateProfileView;
