import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React, { useEffect } from 'react';
import { useDebounce } from 'use-debounce';

interface Props {
  onSearch: (query: string) => void;
  initialQuery?: string;
  debounceDelay?: number;
}

const SearchBar: React.FC<Props> = ({
  onSearch,
  initialQuery = '',
  debounceDelay = 300,
}) => {
  const [query, setQuery] = React.useState(initialQuery);
  const [debouncedQuery] = useDebounce(query, debounceDelay);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  useEffect(() => {
    onSearch(debouncedQuery);
  }, [debouncedQuery, onSearch]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <form className="relative w-full" onSubmit={handleSubmit}>
      <div className="relative">
        <MagnifyingGlassIcon
          className="pointer-events-none absolute left-4 top-2 h-5 w-5 text-gray-900"
          aria-hidden="true"
        />
        <input
          type="text"
          className="sm:text-md block h-full w-full select-none appearance-none rounded-md border-none bg-white py-2 pl-11 pr-10 text-gray-900 transition-all duration-200 placeholder:text-gray-400 focus:border-none focus:outline-none focus:ring-0"
          placeholder="Search"
          style={{
            outline: 'none',
            borderColor: 'transparent !important',
          }}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
    </form>
  );
};

export default SearchBar;
