import { Profile } from '@/types/profiles';
import api from './index';

export interface ProfileCreateRequest {
  complianceTopics: string[];
  regulations: string[];
  services: string[];
  jurisdictions: string[];
  name: string;
  data: Record<string, unknown>;
}

export const createProfile = async (
  profileData: ProfileCreateRequest,
): Promise<Profile> => {
  try {
    const response = await api.post(`profiles`, profileData);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else if (error.message) {
      throw new Error(error.message);
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

export const getProfile = async (profileId: string): Promise<Profile> => {
  const response = await api.get(`profiles/${profileId}`);
  return response.data;
};

export const updateProfile = async (
  profileData: ProfileCreateRequest,
): Promise<Profile> => {
  const response = await api.put(`profiles`, profileData);
  return response.data;
};

export const deleteProfile = async (profileId: string): Promise<void> => {
  await api.delete(`profiles/${profileId}`);
};
