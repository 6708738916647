import ChatFeed from '@/components/Chat/ChatFeed';
import DocumentAsMarkdown from '@/components/DocumentAsMarkdown';
import SearchResultItem from '@/components/Library/SearchResultItem';
import List from '@/components/List/List';
import ScrollContainer from '@/components/List/ScrollContainer';
import ValidUrl from '@/components/ValidUrl';
import SplitPanel from '@/layouts/SplitPanel';
import { SearchResult } from '@/types/search';
import { LinkIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { InboxIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useRef, useState } from 'react';

const NoSearchResults = memo(() => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <InboxIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-lg font-medium">{`No search results found`}</p>
    <p className="text-md mt-2">
      {'Please try updating your filters or check back later for updates'}
    </p>
  </div>
));
NoSearchResults.displayName = 'NoSearchResults';

const SearchResults = memo(
  ({
    view,
    searchResults,
    selectedSearchResult,
    setSelectedSearchResult,
    setSourceId,
    selectedRef,
  }: {
    view: 'narrow' | 'wide';
    searchResults: SearchResult[];
    selectedSearchResult: SearchResult | null;
    setSelectedSearchResult: (searchResult: SearchResult | null) => void;
    setSourceId: (sourceId: number | null) => void;
    selectedRef: React.RefObject<HTMLDivElement>;
  }) => {
    return (
      <ScrollContainer>
        <List
          items={searchResults}
          emptyMessage={<NoSearchResults />}
          renderItem={(searchResult) => (
            <div
              ref={searchResult === selectedSearchResult ? selectedRef : null}
              className="w-full"
            >
              <SearchResultItem
                searchResult={searchResult}
                selectedSearchResult={selectedSearchResult}
                setSelectedSearchResult={setSelectedSearchResult}
                setSourceId={setSourceId}
                view={view}
              />
            </div>
          )}
          classes={{
            li: `hover:bg-gray-50 py-2 ${view === 'narrow' ? 'px-2' : 'px-6'}`,
          }}
        />
      </ScrollContainer>
    );
  },
);
SearchResults.displayName = 'SearchResults';

export default function SearchResultList({
  searchResults,
  selectedSearchResult,
  setSelectedSearchResult,
}: {
  searchResults: SearchResult[];
  selectedSearchResult: SearchResult | null;
  setSelectedSearchResult: (searchResult: SearchResult | null) => void;
}) {
  const [sourceId, setSourceId] = useState<number | null>(null);
  const selectedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedSearchResult && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedSearchResult]);

  return (
    <div className="h-full w-full flex-col">
      {selectedSearchResult ? (
        <SplitPanel
          leftContent={
            <div className="flex h-full flex-col overflow-hidden">
              <ChatFeed
                header={
                  <SearchResultItem
                    searchResult={selectedSearchResult}
                    selectedSearchResult={selectedSearchResult}
                    setSelectedSearchResult={setSelectedSearchResult}
                    setSourceId={setSourceId}
                    view={'narrow'}
                  />
                }
                article={selectedSearchResult.doc}
              />
            </div>
          }
          rightContent={
            <div className="flex h-full flex-col border-l border-gray-200">
              <div className="flex-shrink-0 px-4 py-4 shadow-sm">
                <div className="flex items-center justify-between">
                  <ValidUrl url={selectedSearchResult?.url}>
                    <div className="flex items-center text-blue-500 underline hover:text-blue-600">
                      <div className="text-sm font-medium">
                        {selectedSearchResult?.title}
                      </div>
                      <LinkIcon
                        className="ml-1.5 h-4 w-4 flex-shrink-0 cursor-pointer text-gray-500 hover:text-blue-500"
                        aria-hidden="true"
                      />
                    </div>
                  </ValidUrl>
                  <button
                    onClick={() => {
                      setSelectedSearchResult(null);
                      setSourceId(null);
                    }}
                    className="pl-4 text-gray-500 hover:text-gray-700"
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="flex-grow overflow-hidden">
                <ScrollContainer>
                  <div className="overflow-y-auto">
                    {selectedSearchResult.doc ? (
                      <DocumentAsMarkdown
                        doc={selectedSearchResult.doc}
                        highlightIndex={sourceId}
                      />
                    ) : (
                      <div className="flex-col p-8 text-center text-sm text-gray-800">
                        <div className="">
                          The document is not available for preview
                        </div>
                        <div>
                          Please view the original document{' '}
                          <ValidUrl url={selectedSearchResult.url}>
                            <p className="text-blue-500 hover:underline">
                              here
                            </p>
                          </ValidUrl>
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollContainer>
              </div>
            </div>
          }
        />
      ) : (
        <SearchResults
          view={'wide'}
          searchResults={searchResults}
          selectedSearchResult={selectedSearchResult}
          setSelectedSearchResult={setSelectedSearchResult}
          setSourceId={setSourceId}
          selectedRef={selectedRef}
        />
      )}
    </div>
  );
}
