import Spinner from '@/components/Utils/Spinner';
import { useChat } from '@/hooks/useChat';
import { Article, ChatMessage, Messenger } from '@/types';
import {
  ArrowRightIcon,
  Bars3BottomRightIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';

type Props = {
  article: Article;
  messages: ChatMessage[];
  setMessages: (messages: ChatMessage[]) => void;
};

// Add this new type
type QueryMap = {
  [key: string]: string;
};

export default function ChatBox({ article, messages, setMessages }: Props) {
  const [question, setQuestion] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { fetchError, isFetching, fetchAnswer } = useChat(); // Use the hook

  const handleQuestionSubmit = async (submittedQuestion: string) => {
    const res = await fetchAnswer({
      context: article.chunks.join(' '),
      question: submittedQuestion,
    });
    if (res.answer) {
      const newMessages = [
        ...messages,
        {
          id: messages.length + 1,
          sender: 'user' as Messenger,
          content: submittedQuestion,
        },
        {
          id: messages.length + 2,
          sender: 'bot' as Messenger,
          content: res.answer,
        },
      ];
      setMessages(newMessages);
      setQuestion(''); // Clear the question input
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.rows = 1;
      const rows = Math.max(
        3,
        Math.min(6, Math.ceil(textareaRef.current.scrollHeight / 20)),
      );
      textareaRef.current.rows = rows;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [question]);

  // Add this new state
  const [queryMap] = useState<QueryMap>({
    'Key Points': 'Summarize the key points of this article',
    Obligations:
      'What are the key obligations required to be met based on this article?',
  });

  // Update this function
  const handlePillClick = (query: string) => {
    setQuestion(query);
    handleQuestionSubmit(query);
  };

  return (
    <>
      {/* Add this new section for pills */}
      <div className="mt-4 flex flex-wrap gap-2 px-4">
        {Object.entries(queryMap).map(([key, query]) => (
          <button
            key={key}
            type="button"
            className="flex items-center rounded-full bg-white px-2.5 py-1 text-xs font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => handlePillClick(query)}
          >
            <Bars3BottomRightIcon className="mr-2 h-3 w-3" />
            {key}
          </button>
        ))}
      </div>

      <div className="mt-2 flex-col justify-center px-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleQuestionSubmit(question);
          }}
          className="relative max-w-full flex-auto rounded-lg border border-gray-300 outline-none focus:border-none focus:ring-2 focus:ring-blue-600"
        >
          <div className="overflow-hidden">
            <textarea
              id="question"
              name="question"
              rows={1}
              ref={textareaRef}
              placeholder="Ask a question..."
              className="block w-full resize-y border-0 bg-transparent py-1.5 text-sm text-gray-900 outline-none placeholder:text-gray-400 focus:border-none focus:ring-0"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onInput={adjustTextareaHeight}
            />
            <button
              type="submit"
              className="absolute bottom-2 right-2 z-50 cursor-pointer rounded-md border-0 bg-gray-100 p-2 transition-colors hover:bg-gray-200 focus:ring-0"
            >
              {isFetching ? (
                <Spinner />
              ) : (
                <ArrowRightIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </form>
        {fetchError && (
          <div className="mt-2 w-full text-center text-sm text-red-500">
            Something went wrong, please try again.
          </div>
        )}
      </div>
    </>
  );
}
