import moment from 'moment';

const formattedDate = (dateTime: string, format: string) => {
  return moment(dateTime).format(format);
};

export default function ShortDateTime({
  dateTime,
  format = 'DD MMM, yyyy',
}: {
  dateTime: string;
  format?: string;
}) {
  return <span>{formattedDate(dateTime, format)}</span>;
}
