import DateRangePicker from '@/components/DateRangePicker';
import ProfileFilter from '@/components/Filters/ProfileFilter';
import SideBarFilters from '@/components/Filters/SideBarFilters';
import Pagination from '@/components/List/Pagination';
import ScrollContainer from '@/components/List/ScrollContainer';
import SelectDropdownMenu from '@/components/Menus/SelectDropdownMenu';
import NotificationList from '@/components/Notification/NotificationList';
import Spinner from '@/components/Utils/Spinner';
import { navigationMap } from '@/consts/navigation';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import { useNotificationFilters } from '@/hooks/useNotificationFilters';
import { useNotifications } from '@/hooks/useNotifications';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { getActiveFilter } from '@/lib/utils';
import { useProfilesContext } from '@/providers/profilesProvider';
import {
  Filter,
  SortDirection,
  SortFieldNotification,
  SortOption,
} from '@/types/filter';
import { NotificationResult } from '@/types/notification';
import { Menu, Transition } from '@headlessui/react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  ChartBarIcon,
} from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import urlJoin from 'url-join';

const sortOptions: SortOption<SortFieldNotification, SortDirection>[] = [
  {
    field: 'date',
    direction: 'desc',
    label: 'Date',
    description: 'Date: Newest first',
    icon: CalendarIcon,
  },
  {
    field: 'date',
    direction: 'asc',
    label: 'Date',
    description: 'Date: Oldest first',
    icon: CalendarIcon,
  },
  {
    field: 'risk',
    direction: 'desc',
    label: 'Risk',
    description: 'Risk: Highest first',
    icon: ChartBarIcon,
  },
  {
    field: 'risk',
    direction: 'asc',
    label: 'Risk',
    description: 'Risk: Lowest first',
    icon: ChartBarIcon,
  },
];

export default function RadarPage() {
  usePageVisitTracker('Radar');
  const navigate = useNavigate();
  const [sortField, setSortField] = useState<SortFieldNotification>('date');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setHours(23, 59, 59, 999)),
  );
  const [startDate, setStartDate] = useState<Date>(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setFullYear(date.getFullYear() - 1);
    return date;
  });
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const { profiles, isFetching: isFetchingProfiles } = useProfilesContext();
  const { selectFilterOptions } = useNotificationFilters({
    profileIds: profiles?.map((p) => p.profileId) || [],
  });
  const [filters, setFilters] = useState<Filter[]>(selectFilterOptions);
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);
  const { notificationId } = useParams();
  const [selectedNotification, setSelectedNotificationInner] =
    useState<NotificationResult | null>(null);

  useEffect(() => {
    if (profiles) {
      setSelectedProfileIds(profiles.map((p) => p.profileId));
    }
  }, [profiles]);

  const {
    isFetchingNotifications,
    notificationsError: error,
    notifications,
    totalPages,
    totalCount,
    notificationByIdData,
  } = useNotifications({
    page,
    pageSize,
    profileIds: selectedProfileIds,
    jurisdiction: getActiveFilter('jurisdiction', filters, (value) =>
      JSON.parse(value),
    ),
    regulator: getActiveFilter('authority', filters, (value) =>
      JSON.parse(value),
    ),
    regulation: getActiveFilter('regulation', filters, (value) =>
      JSON.parse(value),
    ),
    documentType: getActiveFilter('documentType', filters, (value) => value),
    topic: getActiveFilter('topic', filters, (value) => value),
    startDate,
    endDate,
    sortField,
    sortDirection,
    searchFilter: query,
    notificationId: selectedNotification ? undefined : notificationId,
  });

  const setSelectedNotification = useCallback(
    (notification: NotificationResult | null) => {
      setSelectedNotificationInner(notification);
      if (!notification) {
        navigate(navigationMap.radar.href);
      } else {
        navigate(urlJoin(navigationMap.radar.href, notification.id));
      }
    },
    [navigate],
  );

  useEffect(() => {
    setFilters(selectFilterOptions);
  }, [selectFilterOptions]);

  useEffect(() => {
    if (notificationId && notificationByIdData) {
      setSelectedNotification(notificationByIdData);
    }
  }, [notificationId, notificationByIdData]);

  const showLoadingSpinner = isFetchingNotifications && !selectedNotification;

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        <div className="flex h-14 w-full items-center justify-between px-4 py-3 text-sm text-gray-900">
          <div className="flex items-center gap-x-1">
            <div className="text-lg font-semibold">Notifications</div>
          </div>
          <div className="relative inline-block flex items-center gap-x-3 px-8 text-left text-sm font-medium">
            <ProfileFilter
              profiles={profiles || []}
              selectedProfileIds={selectedProfileIds}
              onSelectionChange={setSelectedProfileIds}
            />
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => {
                setSelectedNotification(null);
                setStartDate(new Date(date));
              }}
              setEndDate={(date) => {
                setSelectedNotification(null);
                setEndDate(new Date(date));
              }}
              inputClassName="w-[280px] text-sm text-gray-700 rounded-full bg-gray-100 pl-10 pr-3 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
            />
            <SelectDropdownMenu
              label={
                <div className="flex items-center gap-x-2">
                  {sortOptions.map((option) =>
                    option.field === sortField &&
                    option.direction === sortDirection ? (
                      <div
                        key={`${option.field}-${option.direction}`}
                        className="flex items-center gap-x-1"
                      >
                        <span className="text-gray-700">{option.label}</span>
                        {option.direction === 'desc' ? (
                          <ArrowDownIcon className="h-4 w-4 text-gray-700" />
                        ) : (
                          <ArrowUpIcon className="h-4 w-4 text-gray-700" />
                        )}
                      </div>
                    ) : null,
                  )}
                </div>
              }
              buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
            >
              {sortOptions.map((option) => (
                <Menu.Item key={`${option.field}-${option.direction}`}>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setSelectedNotification(null);
                        setSortField(option.field);
                        setSortDirection(option.direction);
                      }}
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } ${
                        sortField === option.field &&
                        sortDirection === option.direction
                          ? 'bg-gray-50'
                          : ''
                      } group flex w-full items-center justify-between px-4 py-2 text-sm`}
                    >
                      <div className="flex items-center">
                        {option.icon && (
                          <option.icon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                        {option.description}
                      </div>
                      {option.direction === 'desc' ? (
                        <ArrowDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                      ) : (
                        <ArrowUpIcon className="ml-2 h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </SelectDropdownMenu>
          </div>
        </div>
        <div className="h-px w-full bg-gray-200"></div>

        <div className="flex w-full flex-1 overflow-hidden">
          <div className="w-[15%] flex-1 overflow-y-auto py-1 pr-1">
            <ScrollContainer>
              <SideBarFilters
                filters={filters}
                setFilters={(e) => {
                  setSelectedNotification(null);
                  setFilters(e);
                }}
              />
            </ScrollContainer>
          </div>
          <div className="flex w-[85%] flex-col overflow-y-auto py-1 pl-1">
            <div className="relative flex-1">
              <Transition
                show={showLoadingSpinner}
                enter="transition ease-out duration-500"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className="absolute inset-0 flex items-center justify-center"
              >
                <Spinner size="sm" />
              </Transition>
              <Transition
                show={!showLoadingSpinner}
                enter="transition ease-out duration-500"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className="absolute inset-0"
              >
                {error ? (
                  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
                    <ExclamationTriangleIcon className="mb-4 h-12 w-12 text-gray-400" />
                    <p className="text-md mt-2">
                      {
                        "We're sorry, but we're having trouble fetching notifications."
                      }
                    </p>
                  </div>
                ) : (
                  <NotificationList
                    notifications={notifications || []}
                    selectedNotification={selectedNotification}
                    setSelectedNotification={setSelectedNotification}
                  />
                )}
              </Transition>
            </div>
            {notifications &&
              notifications?.length > 0 &&
              !selectedNotification &&
              totalCount && (
                <div className="mt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={page}
                    pageSize={pageSize}
                    totalResults={totalCount}
                    padding={2}
                    goToPage={setPage}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
