import backgroundImage from '@/assets/images/blue_spots_bg.png';
import Button from '@/components/Utils/Button';
import LogoWithText from '@/components/Utils/LogoWithText';
import Spinner from '@/components/Utils/Spinner';
import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth';
import { LogoSize, LogoVariant } from '@/types';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDebounce } from 'use-debounce';

function StyledInput({
  title,
  type,
  id,
  name,
  autoComplete,
  placeholder,
  required,
  errorMessage,
  defaultValue,
  onChange,
  className,
}: any) {
  return (
    <div className="mb-4">
      <label htmlFor={id} className="block text-sm font-medium text-slate-700">
        {title}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        onChange={onChange}
        className={`mt-1 block w-full rounded-md border-slate-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 ${className}`}
      />
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

function ErrorDisplay({ message }: { message: string }) {
  return (
    <div
      className={`mt-4 rounded-md bg-red-100 p-4 text-sm text-red-700 transition-opacity duration-300 ${
        message ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {message}
    </div>
  );
}

export default function SignUpPage() {
  const navigate = useNavigate();
  const { signUp } = useAuth();

  const [formState, setFormState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [debouncedEmail] = useDebounce(formState.email, 300);

  useEffect(() => {
    if (debouncedEmail && debouncedEmail !== debouncedEmail.toLowerCase()) {
      setError('Email must be in lowercase.');
    } else {
      setError(undefined);
    }
  }, [debouncedEmail]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);

    if (
      !formState.email ||
      !formState.password ||
      !formState.confirmPassword
    ) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    if (formState.email !== formState.email.toLowerCase()) {
      setError('Email must be in lowercase.');
      setLoading(false);
      return;
    }

    if (formState.password !== formState.confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    const res = await signUp({
      username: formState.email,
      password: formState.password,
      email: formState.email,
    });

    setLoading(false);

    if (res?.success) {
      toast.success('Account created successfully, redirecting to login');
      setTimeout(() => {
        navigate(navigationMap.login.href);
      }, 2000);
    }
    if (!res?.success) {
      setError(
        res?.error || 'Failed to create account, please contact support',
      );
    }
  };

  return (
    <main className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-3/5 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <LogoWithText variant={LogoVariant.LIGHT} size={LogoSize.MEDIUM} />
          <h2 className="mt-8 text-2xl/9 font-semibold text-slate-900">
            Create your account
          </h2>

          <form onSubmit={handleSubmit} className="mt-10 space-y-6">
            <StyledInput
              title="Email"
              type="email"
              id="email"
              name="email"
              autoComplete="email"
              placeholder="Email address"
              required
              defaultValue={formState.email}
              onChange={handleInputChange}
            />

            <div className="relative">
              <StyledInput
                title="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                autoComplete="new-password"
                placeholder="Password"
                required
                defaultValue={formState.password}
                onChange={handleInputChange}
              />
              <span
                onClick={togglePasswordVisibility}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    togglePasswordVisibility();
                  }
                }}
                role="button"
                tabIndex={0}
                className="absolute right-3 top-1/2 mt-2.5 -translate-y-1/2 transform cursor-pointer text-slate-500"
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
              </span>
            </div>

            <div className="relative">
              <StyledInput
                title="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                id="confirm-password"
                name="confirmPassword"
                autoComplete="new-password"
                placeholder="Confirm Password"
                required
                defaultValue={formState.confirmPassword}
                onChange={handleInputChange}
              />
              <span
                onClick={togglePasswordVisibility}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    togglePasswordVisibility();
                  }
                }}
                role="button"
                tabIndex={0}
                className="absolute right-3 top-1/2 mt-2.5 -translate-y-1/2 transform cursor-pointer text-slate-500"
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
              </span>
            </div>

            <Button
              type="submit"
              className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              disabled={
                !formState.email ||
                !formState.password ||
                !formState.confirmPassword ||
                loading
              }
            >
              {loading ? (
                <Spinner size="sm" className="text-white" />
              ) : (
                'Sign up'
              )}
            </Button>
          </form>

          <p className="mt-4 text-sm/6 text-slate-500">
            Already have an account?{' '}
            <Link
              to={navigationMap.login.href}
              className="px-1 font-medium text-blue-600 hover:text-blue-500"
            >
              Sign in
            </Link>
          </p>

          {error && <ErrorDisplay message={error} />}

          <ToastContainer />
        </div>
      </div>
      <div className="relative hidden lg:block lg:w-2/5">
        <img
          alt="Signup background"
          src={backgroundImage}
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </main>
  );
}
