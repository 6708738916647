import {
  createProfile,
  deleteProfile,
  ProfileCreateRequest,
  updateProfile,
} from '@/api/profiles';
import { getUserProfiles } from '@/api/users';
import { Profile } from '@/types/profiles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
const PROFILES_QUERY_KEY = 'PROFILES';

export const useProfiles = (userId: string | undefined) => {
  const queryClient = useQueryClient();
  const [hasProfileData, setHasProfileData] = useState(false);

  const {
    data: profiles,
    error: fetchError,
    isLoading: isFetching,
    refetch,
  } = useQuery<Profile[], Error>({
    queryKey: [PROFILES_QUERY_KEY, userId],
    queryFn: async () => {
      if (!userId) return [];
      const res = await getUserProfiles();
      setHasProfileData(res.length > 0);
      return res;
    },
    enabled: !!userId && !hasProfileData,
  });

  const {
    mutateAsync: createProfileAsync,
    error: createError,
    isPending: isLoading,
  } = useMutation<Profile, Error, { profileData: ProfileCreateRequest }>({
    mutationFn: async ({
      profileData,
    }: {
      profileData: ProfileCreateRequest;
    }) => {
      return await createProfile(profileData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PROFILES_QUERY_KEY, userId],
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const {
    mutateAsync: updateProfileAsync,
    error: updateError,
    isPending: isUpdating,
  } = useMutation<Profile, Error, { profileData: ProfileCreateRequest }>({
    mutationFn: async ({ profileData }) => await updateProfile(profileData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PROFILES_QUERY_KEY, userId],
      });
    },
  });

  const {
    mutateAsync: deleteProfileAsync,
    error: deleteError,
    isPending: isDeleting,
  } = useMutation<void, Error, { profileId: string }>({
    mutationFn: async ({ profileId }) => await deleteProfile(profileId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PROFILES_QUERY_KEY, userId],
      });
    },
  });

  return {
    profiles,
    fetchError,
    isFetching,
    createProfile: createProfileAsync,
    createError,
    isLoading,
    updateProfile: updateProfileAsync,
    updateError,
    isUpdating,
    deleteProfile: deleteProfileAsync,
    deleteError,
    isDeleting,
    refetch,
  };
};
