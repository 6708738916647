import DateRangePicker from '@/components/DateRangePicker';
import SideBarFilters from '@/components/Filters/SideBarFilters';
import SearchBar from '@/components/Library/SearchBar';
import SearchResultList from '@/components/Library/SearchResultList';
import Pagination from '@/components/List/Pagination';
import ScrollContainer from '@/components/List/ScrollContainer';
import SelectDropdownMenu from '@/components/Menus/SelectDropdownMenu';
import Spinner from '@/components/Utils/Spinner';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import { useSearch } from '@/hooks/useSearch';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { getActiveFilter } from '@/lib/utils';
import { useRagFilters } from '@/providers/ragFiltersProvider';
import {
  Filter,
  SortDirection,
  SortFieldSearch,
  SortOption,
} from '@/types/filter';
import { SearchResult } from '@/types/search';
import { Menu, Transition } from '@headlessui/react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
} from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

const sortOptions: SortOption<SortFieldSearch, SortDirection>[] = [
  {
    field: 'date',
    direction: 'desc',
    label: 'Date',
    description: 'Date: Newest first',
    icon: CalendarIcon,
  },
  {
    field: 'date',
    direction: 'asc',
    label: 'Date',
    description: 'Date: Oldest first',
    icon: CalendarIcon,
  },
];

export default function LibraryPage() {
  usePageVisitTracker('Library');
  const [sortField, setSortField] = useState<SortFieldSearch>('date');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const { selectFilterOptions, isFetchingFilters } = useRagFilters();
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedSearchResult, _setSelectedSearchResult] =
    useState<SearchResult | null>(null);

  const setSelectedSearchResult = useCallback(
    (searchResult: SearchResult | null) => {
      _setSelectedSearchResult(searchResult);
    },
    [],
  );

  useEffect(() => {
    setFilters(selectFilterOptions);
  }, [selectFilterOptions]);

  const { isFetching, error, results, totalCount, totalPages } = useSearch({
    pageSize,
    page,
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    query,
    dateRange: [
      startDate?.toISOString() ?? null,
      endDate?.toISOString() ?? null,
    ],
    jurisdiction: getActiveFilter('jurisdiction', filters, (value) =>
      JSON.parse(value),
    ),
    regulator: getActiveFilter('authority', filters, (value) =>
      JSON.parse(value),
    ),
    regulation: getActiveFilter('regulation', filters, (value) =>
      JSON.parse(value),
    ),
    documentType: getActiveFilter('documentType', filters, (value) => value),
    topic: getActiveFilter('topic', filters, (value) => value),
  });

  const showLoadingSpinner = isFetching || isFetchingFilters;

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        <div className="w-full py-3">
          <div className="flex items-center justify-between text-sm text-gray-900">
            <div className="flex items-center gap-x-1">
              <SearchBar onSearch={(query) => setQuery(query)} />
            </div>

            <div className="relative inline-block flex items-center gap-x-3 px-8 text-left text-sm font-medium">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={(date) => {
                  setSelectedSearchResult(null);
                  setStartDate(new Date(date));
                }}
                setEndDate={(date) => {
                  setSelectedSearchResult(null);
                  setEndDate(new Date(date));
                }}
                inputClassName="w-[280px] text-sm text-gray-700 rounded-full bg-gray-100 pl-10 pr-3 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
              />
              <SelectDropdownMenu
                label={
                  <div className="flex items-center gap-x-2">
                    {sortOptions.map((option) =>
                      option.field === sortField &&
                      option.direction === sortDirection ? (
                        <div
                          key={`${option.field}-${option.direction}`}
                          className="flex items-center gap-x-1"
                        >
                          <span className="text-gray-700">{option.label}</span>
                          {option.direction === 'desc' ? (
                            <ArrowDownIcon className="h-4 w-4 text-gray-700" />
                          ) : (
                            <ArrowUpIcon className="h-4 w-4 text-gray-700" />
                          )}
                        </div>
                      ) : null,
                    )}
                  </div>
                }
                buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
              >
                {sortOptions.map((option) => (
                  <Menu.Item key={`${option.field}-${option.direction}`}>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setSelectedSearchResult(null);
                          setSortField(option.field);
                          setSortDirection(option.direction);
                        }}
                        className={`${
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700'
                        } ${
                          sortField === option.field &&
                          sortDirection === option.direction
                            ? 'bg-gray-50'
                            : ''
                        } group flex w-full items-center justify-between px-4 py-2 text-sm`}
                      >
                        <div className="flex items-center">
                          {option.icon && (
                            <option.icon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          )}
                          {option.description}
                        </div>
                        {option.direction === 'desc' ? (
                          <ArrowDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                        ) : (
                          <ArrowUpIcon className="ml-2 h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </SelectDropdownMenu>
            </div>
          </div>
        </div>
        <div className="h-px w-full bg-gray-200"></div>

        <div className="flex w-full flex-1 overflow-hidden">
          <div className="w-[15%] flex-1 overflow-y-auto py-1 pr-1">
            <ScrollContainer>
              <SideBarFilters
                filters={filters}
                setFilters={(e) => {
                  setSelectedSearchResult(null);
                  setFilters(e);
                }}
              />
            </ScrollContainer>
          </div>
          <div className="flex w-[85%] flex-col overflow-y-auto py-1 pl-1">
            <div className="relative flex-1">
              <Transition
                show={showLoadingSpinner}
                enter="transition ease-out duration-500"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className="absolute inset-0 flex items-center justify-center"
              >
                <Spinner size="sm" />
              </Transition>
              <Transition
                show={!showLoadingSpinner}
                enter="transition ease-out duration-500"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className="absolute inset-0"
              >
                {error ? (
                  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
                    <ExclamationTriangleIcon className="mb-4 h-12 w-12 text-gray-400" />
                    <p className="text-md mt-2">
                      {
                        "We're sorry, but we're having trouble fetching the results."
                      }
                    </p>
                  </div>
                ) : (
                  <SearchResultList
                    searchResults={results || []}
                    selectedSearchResult={selectedSearchResult}
                    setSelectedSearchResult={setSelectedSearchResult}
                  />
                )}
              </Transition>
            </div>
            {results &&
              results?.length > 0 &&
              !selectedSearchResult &&
              totalCount && (
                <div className="mt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={page}
                    pageSize={pageSize}
                    totalResults={totalCount}
                    padding={2}
                    goToPage={setPage}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
