import Accordion from '@/components/Filters/Accordion';
import SearchFilter from '@/components/Filters/SearchFilter';
import VirtualizedList from '@/components/List/VirtualizedList';
import { Filter, FilterOption } from '@/types/filter';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface FilterOptionProps {
  section: Filter;
  option: FilterOption;
  onOptionClick?: (option: { section: Filter; option: FilterOption }) => void;
  updateFilters: (checked: boolean) => void;
}

const SelectOption: React.FC<FilterOptionProps> = ({
  section,
  option,
  onOptionClick,
  updateFilters,
}) => (
  <div className="flex items-start">
    <input
      id={`filter-${section.id}-${option.value}`}
      name={`${section.id}[]`}
      value={option.value}
      type="checkbox"
      checked={option.checked}
      className="mt-1 h-4 w-4 flex-shrink-0 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (onOptionClick) onOptionClick({ section, option });
        updateFilters(e.target.checked);
      }}
    />
    <label
      htmlFor={`filter-${section.id}-${option.value}`}
      className="ml-3 min-w-0 flex-1 truncate whitespace-nowrap text-sm text-gray-600"
    >
      <span className="inline-block w-full break-all">{option.label}</span>
    </label>
  </div>
);

interface SelectAllOptionProps {
  sectionId: string;
  options: FilterOption[];
  updateAllFilters: (checked: boolean) => void;
}

const SelectAllOption: React.FC<SelectAllOptionProps> = ({
  sectionId,
  options,
  updateAllFilters,
}) => (
  <div className="flex items-center">
    <input
      id={`filter-${sectionId}-all`}
      name={`${sectionId}[]`}
      value="all"
      checked={options.every((o) => o.checked)}
      type="checkbox"
      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        updateAllFilters(e.target.checked)
      }
    />
    <label
      htmlFor={`filter-${sectionId}-all`}
      className="ml-3 text-sm text-gray-600"
    >
      Select All
    </label>
  </div>
);

interface SelectedOptionPillProps {
  label: string;
  onRemove: () => void;
}

const SelectedOptionPill: React.FC<SelectedOptionPillProps> = ({
  label,
  onRemove,
}) => (
  <div className="inline-flex w-fit max-w-full items-center gap-1 truncate whitespace-nowrap rounded-full bg-gray-200 px-2 py-1 text-xs text-gray-700">
    <button
      onClick={onRemove}
      className="flex w-full items-center gap-1 text-gray-700 hover:text-gray-900"
    >
      <XMarkIcon className="h-4 w-4 flex-shrink-0" />
      <span className="truncate">{label}</span>
    </button>
  </div>
);

// Update the main component to use these new typed components
interface SideBarFiltersProps {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  header?: JSX.Element;
  onOptionClick?: (option: { section: Filter; option: FilterOption }) => void;
}

const SideBarFilters: React.FC<SideBarFiltersProps> = ({
  header,
  filters,
  setFilters,
  onOptionClick,
}) => {
  // Add state for filter searches
  const [filterSearches, setFilterSearches] = React.useState<{
    [key: string]: string;
  }>(Object.fromEntries(filters.map((filter) => [filter.id, ''])));

  // Function to update filter search
  const updateFilterSearch = (filterId: string, search: string) => {
    setFilterSearches((prev) => ({ ...prev, [filterId]: search }));
  };

  // Function to filter options based on search
  const filterOptions = (options: FilterOption[], search: string) => {
    if (!search) return options;
    const lowerSearch = search.toLowerCase();
    return options.filter((option) =>
      option.label.toLowerCase().includes(lowerSearch),
    );
  };

  // Update the FilterOption component
  const updateFilters = (
    sectionId: string,
    optionValue: string,
    checked: boolean,
  ) => {
    setFilters((prevFilters) =>
      prevFilters.map((f) =>
        f.id === sectionId
          ? {
              ...f,
              options: f.options.map((o) =>
                o.value === optionValue ? { ...o, checked: checked } : o,
              ),
            }
          : f,
      ),
    );
  };

  const updateAllFilters = (sectionId: string, checked: boolean) => {
    setFilters((prevFilters) =>
      prevFilters.map((f) =>
        f.id === sectionId
          ? {
              ...f,
              options: f.options.map((o) => ({ ...o, checked: checked })),
            }
          : f,
      ),
    );
  };

  const clearAllFilters = () => {
    setFilters((prevFilters) =>
      prevFilters.map((f) => ({
        ...f,
        options: f.options.map((o) => ({ ...o, checked: false })),
      })),
    );
    setFilterSearches((prev) =>
      Object.fromEntries(Object.keys(prev).map((key) => [key, ''])),
    );
  };

  return (
    <div className="flex h-full flex-col bg-white">
      <div className="flex h-full flex-1 flex-col overflow-hidden">
        <main className="flex-1 px-2 sm:px-4">
          {!!header && (
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
              <div className="flex items-center">{header}</div>
            </div>
          )}
          <button
            className="font-base inline-flex items-center rounded-md pt-4 text-sm text-gray-600 hover:underline"
            onClick={clearAllFilters}
          >
            Clear Filters
          </button>
          <div className="h-full">
            <form className="hidden h-full lg:block">
              {filters.map((section) => (
                <Accordion
                  header={
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-2">
                        <span className="font-medium text-gray-900">
                          {section.name}
                        </span>
                      </div>
                    </div>
                  }
                  key={section.id}
                  defaultOpen={section.defaultOpen}
                  closedChildren={
                    <div className="flex max-w-full flex-wrap gap-1.5 pt-3 font-normal">
                      {section.options
                        .filter((o) => o.checked)
                        .slice(0, 5)
                        .map((o) => (
                          <SelectedOptionPill
                            key={o.value}
                            label={o.label}
                            onRemove={() =>
                              updateFilters(section.id, o.value, false)
                            }
                          />
                        ))}
                      {section.options.filter((o) => o.checked).length > 5 && (
                        <span className="text-sm text-gray-500">
                          {section.options.filter((o) => o.checked).length - 5}{' '}
                          more active
                        </span>
                      )}
                    </div>
                  }
                >
                  <div className="space-y-4">
                    {/* Add search bar for each filter if more than 10 options */}
                    {section.options.length > 10 ? (
                      <>
                        <SearchFilter
                          query={filterSearches[section.id]}
                          setQuery={(search: string) =>
                            updateFilterSearch(section.id, search)
                          }
                        />
                        <VirtualizedList
                          height={300}
                          items={filterOptions(
                            section.options,
                            filterSearches[section.id],
                          ).map((option) => (
                            <SelectOption
                              key={option.value}
                              section={section}
                              option={option}
                              onOptionClick={onOptionClick}
                              updateFilters={(checked: boolean) => {
                                updateFilters(
                                  section.id,
                                  option.value,
                                  checked,
                                );
                              }}
                            />
                          ))}
                        />
                      </>
                    ) : (
                      <ul>
                        {filterOptions(
                          section.options,
                          filterSearches[section.id],
                        ).map((item) => (
                          <li key={item.value} className="py-2">
                            <SelectOption
                              section={section}
                              option={item}
                              onOptionClick={onOptionClick}
                              updateFilters={(checked: boolean) => {
                                updateFilters(section.id, item.value, checked);
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                    {/* last option to select all that sets all to true on select and all to false on deselect */}
                    <SelectAllOption
                      sectionId={section.id}
                      options={section.options}
                      updateAllFilters={(checked: boolean) =>
                        updateAllFilters(section.id, checked)
                      }
                    />
                  </div>
                </Accordion>
              ))}
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SideBarFilters;
