import classNames from 'classnames';

type PillSize = 'xs' | 'sm' | 'md' | 'lg';
type PillVariant = 'solid' | 'outline';

interface PillProps {
  text: string;
  leftIcon?: React.ReactNode;
  onClick?: () => void;
  variant?: PillVariant;
  size?: PillSize;
}

const Pill: React.FC<PillProps> = ({
  text,
  leftIcon,
  onClick,
  variant = 'solid',
  size = 'md',
}: PillProps) => {
  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2.5 py-1.5 text-sm',
    md: 'px-3 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
  };

  const variantClasses = {
    solid: 'bg-gray-100 text-gray-800',
    outline: 'border border-gray-400 text-gray-600 bg-white',
  };

  const pillClasses = classNames(
    'inline-flex items-center rounded-full',
    sizeClasses[size],
    variantClasses[variant],
  );

  return (
    <span className={pillClasses}>
      <button
        type="button"
        onClick={onClick}
        className="mr-2 flex items-center"
      >
        {leftIcon && <span className="mr-2">{leftIcon}</span>}
        {text}
      </button>
    </span>
  );
};

export default Pill;
