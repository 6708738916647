import * as Tooltip from '@radix-ui/react-tooltip';

interface BaseTooltipProps {
  tooltipContent: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  className?: string;
  children: React.ReactNode;
  showArrow?: boolean;
}

export default function BaseTooltip({
  tooltipContent,
  side = 'top',
  sideOffset = 8,
  className,
  children,
  showArrow = false,
}: BaseTooltipProps) {
  return (
    <Tooltip.Provider delayDuration={50}>
      <Tooltip.Root>
        <Tooltip.Trigger className="flex items-center gap-x-2">
          {children}
          <Tooltip.Content
            side={side}
            sideOffset={sideOffset}
            className={
              className ||
              `z-50 rounded-md bg-gray-700 px-2 py-1 text-xs text-white opacity-90`
            }
          >
            {tooltipContent}
            {showArrow && <Tooltip.Arrow className="fill-gray-700" />}
          </Tooltip.Content>
        </Tooltip.Trigger>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
