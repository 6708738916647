import api from './index';

export const askQuestion = async (
  context: string,
  question: string,
): Promise<{ answer: string }> => {
  try {
    const response = await api.post(`ask`, { context, question });
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else if (error.message) {
      throw new Error(error.message);
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};
