import { navigationMap } from '@/consts/navigation';
import { useAuthContext } from '@/providers/authProvider';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface RequiresAuthProps {
  children: React.ReactNode;
}

const RequiresAuth: React.FC<RequiresAuthProps> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return <Navigate to={navigationMap.login.href} />;
  }

  return <>{children}</>;
};

export default RequiresAuth;
