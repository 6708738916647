import { getFilterById } from '@/lib/utils';
import { Filter, FilterKey } from '@/types/filter';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import MultiSelectDropdownMenu from '../Menus/MultiSelectDropdownMenu';
import Spinner from '../Utils/Spinner';
import SearchBar from './SearchBar';

const SearchPalette = ({
  onSearch,
  searchFilters,
  setSearchFilters,
  isFetchingFilters,
  initialQuery,
}: {
  onSearch: (query: string) => void;
  searchFilters: Filter[];
  setSearchFilters: (filters: any[]) => void;
  isFetchingFilters: boolean;
  initialQuery?: string;
}) => {
  const handleSearch = (query: string) => {
    onSearch(query);
    const clearedFilters = searchFilters.map((filter) => ({
      ...filter,
      options: filter.options.map((opt) => ({
        ...opt,
        checked: false,
      })),
    }));
    setSearchFilters(clearedFilters);
  };

  const getNumSelected = (filterId: FilterKey) =>
    getFilterById(filterId, searchFilters)?.options?.filter(
      (opt) => opt.checked,
    ).length ?? 0;

  const renderFilterMenu = (filterId: FilterKey, label: string) => {
    const filter = getFilterById(filterId, searchFilters);
    if (!filter) return null;

    const numSelected = getNumSelected(filterId);

    return (
      <MultiSelectDropdownMenu
        label={label}
        buttonContent={() => (
          <div className="flex items-center gap-2">
            {label}
            <div className="flex items-center">
              {numSelected > 0 && (
                <span className="rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-700">
                  {numSelected}
                </span>
              )}
              <ChevronDownIcon className="h-4 w-4 text-gray-500" />
            </div>
          </div>
        )}
        options={filter.options.map((opt) => ({
          id: opt.value,
          label: opt.label,
        }))}
        selectedItems={filter.options
          .filter((opt) => opt.checked)
          .map((opt) => opt.value)}
        onSelectionChange={(selected) => {
          const newFilters = [...searchFilters];
          const currentFilter = getFilterById(filterId, newFilters);
          if (currentFilter) {
            currentFilter.options = currentFilter.options.map((opt) => ({
              ...opt,
              checked: selected.includes(opt.value),
            }));
            setSearchFilters(newFilters);
          }
        }}
        scrollAreaClassName="h-60"
        buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors min-w-[150px]"
        showSearch
      />
    );
  };

  return (
    <div className="w-full max-w-3xl space-y-8">
      {/* Search section */}
      <div className="space-y-4">
        {/* Search bar */}
        <div className="w-full">
          <SearchBar onSearch={handleSearch} initialQuery={initialQuery} />
        </div>

        {/* Loading spinner */}
        {isFetchingFilters ? (
          <div className="flex justify-center">
            <Spinner size="sm" />
          </div>
        ) : (
          /* Filters */
          <div className="flex flex-nowrap items-center justify-center gap-2">
            {renderFilterMenu('jurisdiction', 'Jurisdiction')}
            {renderFilterMenu('authority', 'Authority')}
            {/* {renderFilterMenu('regulation', 'Regulation')} */}
            {renderFilterMenu('documentType', 'Document')}
            {/* {renderFilterMenu('topic', 'Topic')} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPalette;
