import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface Props {
  query: string;
  setQuery: (query: string) => void;
}

const SearchFilter: React.FC<Props> = ({ query, setQuery }) => {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
  };

  const clearQuery = () => {
    setQuery('');
  };

  return (
    <div
      className={`relative text-sm ${query ? 'text-gray-900' : 'text-gray-500 hover:text-gray-600'}`}
    >
      <MagnifyingGlassIcon
        className={`absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform`}
      />
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search..."
        className="w-full rounded-lg border border-gray-300 px-2 pl-10 text-sm outline-none focus:border-none focus:ring-2 focus:ring-blue-600"
      />
      {query && (
        <XMarkIcon
          className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 transform cursor-pointer text-gray-500 hover:text-gray-600"
          onClick={clearQuery}
        />
      )}
    </div>
  );
};

export default SearchFilter;
