import { Profile } from '@/types/profiles';
import {
  ChevronRightIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import Button from '../Utils/Button';
import ProfileAvatar from './ProfileAvatar';

const NUM_ITEMS_TO_SHOW = 2;

interface ProfileListItemProps {
  profile: Profile;
  onClick: () => void;
  onEdit: () => void;
  isSelected: boolean;
}

const ProfileListItem: React.FC<ProfileListItemProps> = ({
  profile,
  onClick,
  onEdit,
  isSelected,
}) => {
  const renderSection = (title: string, items: string[]) => {
    if (items.length === 0) return null;

    return (
      <div className="mt-4">
        <h4 className="text-sm font-medium text-gray-600">{title}</h4>
        <div className="mt-2 flex items-center">
          {items.slice(0, NUM_ITEMS_TO_SHOW).map((item, idx) => (
            <span
              key={idx}
              className="mr-2 rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-600"
            >
              {item}
            </span>
          ))}
          {items.length > NUM_ITEMS_TO_SHOW && (
            <span className="flex items-center text-xs text-gray-500">
              <ChevronRightIcon className="h-4 w-4" />
              {items.length - NUM_ITEMS_TO_SHOW} more
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <div
      className={`w-full cursor-pointer p-2 text-left transition-colors duration-200 ease-in-out ${
        isSelected ? 'bg-blue-50' : 'hover:bg-gray-50'
      }`}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <div className="right-2 top-2 float-right">
        <div className="flex flex-col space-y-2">
          <Button
            variant="outline"
            size="sm"
            icon={EyeIcon}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            View
          </Button>
          <Button
            variant="outline"
            size="sm"
            icon={PencilIcon}
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            Edit
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ProfileAvatar name={profile.name} size="base" />
          <span className="ml-3 text-lg font-medium">
            {profile.name || 'Unnamed Profile'}
          </span>
        </div>
      </div>
      <div className="mt-4">
        {renderSection('Regulations', profile.regulations)}
        {renderSection('Services', profile.services)}
        {renderSection('Compliance Topics', profile.complianceTopics)}
        {/* {renderSection('Jurisdictions', profile.jurisdictions)} */}
      </div>
    </div>
  );
};

export default ProfileListItem;
