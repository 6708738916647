import { SearchResult } from '@/types/search';
import { BuildingLibraryIcon, ScaleIcon } from '@heroicons/react/16/solid';
import { FileText } from '@phosphor-icons/react';
import { useState } from 'react';
import ShortDateTime from '../DateTime/ShortDateTime';
import Pill from '../Pill';

function SearchResultContent({
  searchResult,
  setSourceId,
  selectedSearchResult,
  setSelectedSearchResult,
  view,
}: {
  searchResult: SearchResult;
  setSourceId: (sourceId: number) => void;
  selectedSearchResult: SearchResult | null;
  setSelectedSearchResult: (searchResult: SearchResult | null) => void;
  view: 'narrow' | 'wide';
}) {
  const isNarrowView = view === 'narrow';

  const renderPills = () => (
    <div
      className={`flex items-start space-x-2 ${
        isNarrowView ? '' : 'ml-auto pl-4'
      }`}
    >
      {searchResult.regulators?.[0] && (
        <Pill
          variant="outline"
          size="xs"
          text={
            searchResult.regulators?.[0]?.shortName ||
            (searchResult.regulators?.[0]?.longName as string)
          }
          leftIcon={<BuildingLibraryIcon className="h-3.5 w-3.5" />}
        />
      )}
      {searchResult.regulations?.[0] && (
        <Pill
          variant="outline"
          size="xs"
          text={
            searchResult.regulations?.[0]?.shortName ||
            (searchResult.regulations?.[0]?.longName as string)
          }
          leftIcon={<ScaleIcon className="h-3.5 w-3.5" />}
        />
      )}
      {searchResult.documentType && (
        <Pill
          variant="outline"
          size="xs"
          text={searchResult.documentType}
          leftIcon={<FileText className="h-3.5 w-3.5" weight="fill" />}
        />
      )}
    </div>
  );

  return (
    <button
      className={`relative flex w-full items-start justify-between py-2 ${view === 'narrow' ? 'gap-x-3 px-1' : 'gap-x-4 px-2'} `}
      onClick={() => {
        // if notification is already selected, deselect it
        if (searchResult === selectedSearchResult) {
          setSelectedSearchResult(null);
          return;
        }
        setSelectedSearchResult(searchResult);
      }}
    >
      <div
        className={`flex w-full flex-col items-start ${
          isNarrowView ? '' : 'gap-x-4'
        }`}
      >
        {isNarrowView && <div className="mb-2">{renderPills()}</div>}
        <div className={`flex w-full items-start justify-between gap-x-2`}>
          <div className="flex-grow">
            <p className="max-w-full text-left text-sm font-medium leading-6 text-gray-900 sm:max-w-6xl">
              {searchResult.title}
            </p>
            {searchResult.datePublished && (
              <p className="mt-1 text-left text-xs text-gray-500">
                <ShortDateTime dateTime={searchResult.datePublished} />
              </p>
            )}
          </div>
          {!isNarrowView && renderPills()}
        </div>
      </div>
    </button>
  );
}

export default function SearchResultItem({
  searchResult,
  selectedSearchResult,
  setSelectedSearchResult,
  setSourceId,
  view,
}: {
  searchResult: SearchResult;
  selectedSearchResult: SearchResult | null;
  setSelectedSearchResult: (searchResult: SearchResult | null) => void;
  setSourceId: (sourceId: number) => void;
  view: 'narrow' | 'wide';
}) {
  const [isOpen, setIsOpen] = useState(
    selectedSearchResult?.contentId === searchResult.contentId,
  );

  return (
    <div className="w-full">
      <SearchResultContent
        searchResult={searchResult}
        setSourceId={setSourceId}
        selectedSearchResult={selectedSearchResult}
        setSelectedSearchResult={setSelectedSearchResult}
        view={view}
      />
    </div>
  );
}
