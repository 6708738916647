import ScrollArea from '@/layouts/ScrollArea';

interface Props {
  header?: React.ReactNode;
  children: React.ReactNode;
}

export default function ScrollContainer({ header, children }: Props) {
  return (
    <ScrollArea>
      {!!header && (
        <div className="sticky top-0 z-10 border-b border-gray-200 bg-white sm:rounded-t-xl">
          {header}
        </div>
      )}
      {children}
    </ScrollArea>
  );
}
