import backgroundImage from '@/assets/images/blue_spots_bg.png';
import Button from '@/components/Utils/Button';
import LogoWithText from '@/components/Utils/LogoWithText';
import Spinner from '@/components/Utils/Spinner';
import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth'; // Import the custom hook
import { LogoSize, LogoVariant } from '@/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ErrorDisplay({ message }: { message: string }) {
  return (
    <div
      className={`mt-4 rounded-md bg-red-100 p-4 text-sm text-red-700 transition-opacity duration-300 ${
        message ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {message}
    </div>
  );
}

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { requestPasswordReset } = useAuth();
  const navigate = useNavigate();

  const handleRequestCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    const res = await requestPasswordReset(email);
    setLoading(false);
    if (res?.success) {
      toast.success('Verification code sent to your email');
      setTimeout(() => {
        navigate(navigationMap.resetPassword.href);
      }, 2000);
    }
    if (!res?.success) {
      setError(
        res?.error ||
          'Failed to send verification code, please contact support',
      );
    }
  };

  return (
    <main className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-3/5 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <LogoWithText variant={LogoVariant.LIGHT} size={LogoSize.MEDIUM} />
            <h2 className="mt-8 text-2xl/9 font-semibold text-slate-900">
              Forgot Password
            </h2>
          </div>

          <div className="mt-10">
            <form onSubmit={handleRequestCode} className="space-y-6">
              <p className="mb-4 text-sm text-slate-500">
                Enter your email to receive a verification code.
              </p>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-4 block w-full rounded-md border-slate-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                required
              />
              <Button
                type="submit"
                className="flex w-full items-center justify-center rounded-md bg-blue-600 py-2 text-white shadow-sm hover:bg-blue-500"
              >
                {loading ? (
                  <Spinner size="sm" className="text-white" />
                ) : (
                  'Request Code'
                )}
              </Button>
            </form>

            {error && <ErrorDisplay message={error} />}

            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="relative hidden lg:block lg:w-2/5">
        <img
          alt="Forgot Password background"
          src={backgroundImage}
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </main>
  );
}
