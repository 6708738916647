import {
  ChatMessage,
  LogoVariant,
  Article as NotificationArticle,
} from '@/types';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import ScrollContainer from '../List/ScrollContainer';
import { Logo } from '../Utils/Logo';
import ChatBox from './ChatBox'; // Import ChatBox component

interface Props {
  article: NotificationArticle;
  header?: ReactNode;
}

const proseTextStyle = (isHighlighted: boolean) =>
  isHighlighted ? 'bg-yellow-100' : '';

export default function ChatFeed({ article, header }: Props) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex h-full flex-col">
      <ScrollContainer>
        {header && <div className="p-4">{header}</div>}
        <div className="h-full w-full space-y-4 rounded p-4">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`flex w-full py-1 ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              {message.sender === 'bot' && (
                <div className="-mt-0.5 mr-2 flex items-start">
                  <div className="flex items-center justify-center rounded-full bg-gray-200 p-2">
                    <Logo variant={LogoVariant.LIGHT} classes={`h-auto w-4`} />
                  </div>
                </div>
              )}
              <div
                className={`flex rounded-lg ${message.sender === 'user' ? 'max-w-[60%] bg-gray-100 px-4 py-2' : 'max-w-[80%]'}`}
              >
                <Markdown
                  className={`prose prose-sm w-full min-w-full max-w-none flex-grow flex-col ${proseTextStyle(false)}`}
                  components={{
                    /* eslint-disable */
                    p: ({ node, ...props }) => (
                      <p {...props} className="w-full text-left" />
                    ),
                    li: ({ node, ...props }) => (
                      <li {...props} className="-my-1 w-full text-left" />
                    ),
                    ul: ({ node, ...props }) => (
                      <ul {...props} className="w-full text-left" />
                    ),
                    /* eslint-enable */
                  }}
                  disallowedElements={['pre', 'code']}
                >
                  {message.content}
                </Markdown>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </ScrollContainer>
      <div className="py-4">
        <ChatBox
          messages={messages}
          setMessages={setMessages}
          article={article}
        />
      </div>
    </div>
  );
}
