import { handleGetSession } from '@/hooks/useAuth';
import axios from 'axios';
import { toCamel, toSnake } from 'snake-camel';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(async (config) => {
  const session = await handleGetSession();
  const token = session?.tokens?.idToken;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Helper function to deeply transform an object to snake_case
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepSnakeCaseTransform = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(deepSnakeCaseTransform);
  } else if (obj !== null && obj.constructor === Object) {
    return toSnake(obj);
  }
  return obj;
};

// Add a request interceptor to convert camelCase to snake_case deeply
api.interceptors.request.use(
  (config) => {
    if (config.data) {
      config.data = deepSnakeCaseTransform(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Helper function to deeply transform an object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepCamelCaseTransform = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(deepCamelCaseTransform);
  } else if (obj !== null && obj.constructor === Object) {
    return toCamel(obj);
  }
  return obj;
};

// Add a response interceptor to convert snake_case to camelCase deeply
api.interceptors.response.use(
  (response) => {
    response.data = deepCamelCaseTransform(response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
