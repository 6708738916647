import React from 'react';

import NavBar from '@/components/Navigation/NavBar';
import SideBar from '@/components/Navigation/SideBar';

type Props = {
  children: React.ReactNode;
};

export const NavLayout: React.FC<Props> = ({ children }: Props) => (
  <div className="flex h-screen w-screen">
    <div className="hidden sm:block">
      <SideBar />
    </div>
    <div className="flex h-full w-full flex-1 flex-col">
      <NavBar />
      <main className="h-full overflow-y-auto">
        <div className="mx-auto h-full flex-1 px-4">{children}</div>
      </main>
    </div>
  </div>
);

export default NavLayout;
