import ScrollContainer from '@/components/List/ScrollContainer';
import { Profile } from '@/types/profiles';
import {
  BookOpenIcon,
  BriefcaseIcon,
  GlobeAltIcon,
  PencilIcon,
  ScaleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import Button from '../Utils/Button';
import Modal from '../Utils/Modal';
import ProfileAvatar from './ProfileAvatar';

interface ProfileViewProps {
  profile: Profile;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ProfileView: React.FC<ProfileViewProps> = ({
  profile,
  onClose,
  onEdit,
  onDelete,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <ScrollContainer>
      <div className="p-6">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <ProfileAvatar name={profile.name} className="mr-4" size="base" />
            <h2 className="text-xl font-semibold text-gray-900">
              {profile.name}
            </h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500"
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <ProfileSection title="Regulations" items={profile.regulations} />
        <ProfileSection title="Services" items={profile.services} />
        <ProfileSection
          title="Compliance Topics"
          items={profile.complianceTopics}
        />
        {/* <ProfileSection title="Jurisdictions" items={profile.jurisdictions} /> */}
      </div>
      <div className="flex justify-end gap-2 px-6">
        <Button variant="primary" size="md" icon={PencilIcon} onClick={onEdit}>
          Edit Profile
        </Button>
        <Button
          variant="danger"
          size="md"
          icon={TrashIcon}
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete Profile
        </Button>
      </div>
      <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-medium">Delete Profile</h2>
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this profile?
          </p>
          <div className="flex justify-end gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" size="sm" onClick={onDelete}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </ScrollContainer>
  );
};

const ProfileSection: React.FC<{ title: string; items: string[] }> = ({
  title,
  items,
}) => {
  if (items.length === 0) return null;
  const Icon = sectionIcons[title as keyof typeof sectionIcons];
  return (
    <div className="mt-8">
      <div className="mb-2 flex items-center">
        <span className="mr-3 inline-flex rounded-lg bg-gray-100 p-2 text-gray-700 ring-4 ring-white">
          <Icon className="h-[18px] w-[18px]" aria-hidden="true" />
        </span>
        <h3 className="text-base font-medium text-gray-700">{title}</h3>
      </div>
      <hr className="mb-4 border-t border-gray-200" />
      <ul className="space-y-2">
        {items.map((item, idx) => (
          <li key={idx} className="text-sm text-gray-600">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const sectionIcons = {
  Regulations: ScaleIcon,
  Services: BriefcaseIcon,
  'Compliance Topics': BookOpenIcon,
  Jurisdictions: GlobeAltIcon,
};

export default ProfileView;
