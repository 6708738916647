import useAuth from '@/hooks/useAuth';
import { Hub } from '@aws-amplify/core';
import { useEffect } from 'react';

const useSignUpListener = () => {
  const { getUserId } = useAuth();

  useEffect(() => {
    // TODO: this is not called at present as we are manually signing up users
    const handleSignUp = async (): Promise<null> => {
      await getUserId();
      // TODO: handle, const userId
      return null;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = (data: any) => {
      const { payload } = data;
      if (payload.event === 'signUp') {
        handleSignUp();
      }
    };

    Hub.listen('auth', listener);
  }, [getUserId]);
};

export default useSignUpListener;
