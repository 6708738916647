import { LogoVariant } from '@/types';

import logoBlack from '@/assets/icons/logo.svg';
import logoWhite from '@/assets/icons/logo.svg';

export interface LogoProps {
  classes?: string;
  variant?: LogoVariant;
}

export function Logo({ classes, variant }: LogoProps) {
  return (
    <img
      src={variant === LogoVariant.LIGHT ? logoWhite : logoBlack}
      alt="Keenly"
      className={classes}
    />
  );
}
