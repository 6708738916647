import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';

interface TrackingProviderProps {
  children: ReactNode;
}

const TrackingProvider = ({ children }: TrackingProviderProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      posthog.init(process.env.REACT_APP_POSTHOG_API_KEY || '', {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      });
    }
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default TrackingProvider;
