import React, { useEffect, useState } from 'react';

interface SpinnerProps {
  loadingStatements?: string[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  loadingStatements,
  size = 'sm',
  className = 'ml-3 mr-3',
}) => {
  const [statementIndex, setStatementIndex] = useState(0);

  useEffect(() => {
    if (!loadingStatements) return;
    const interval = setInterval(() => {
      setStatementIndex(
        (prevIndex) => (prevIndex + 1) % loadingStatements.length,
      );
    }, 7000);
    return () => clearInterval(interval);
  }, [loadingStatements]);

  const sizeClasses = {
    xs: 'h-4 w-4',
    sm: 'h-6 w-6',
    md: 'h-8 w-8',
    lg: 'h-10 w-10',
    xl: 'h-12 w-12',
    '2xl': 'h-16 w-16',
  };

  return (
    <div className="flex flex-col items-center">
      <svg
        className={`animate-spin ${sizeClasses[size]} ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      {loadingStatements && (
        <span className="mt-4">{loadingStatements[statementIndex]}</span>
      )}
    </div>
  );
};

export default Spinner;
