import { LogoVariant } from '@/types';

export interface BrandTextProps {
  size?: string;
  classes?: string;
  variant?: LogoVariant;
}

export default function BrandText({ classes, variant }: BrandTextProps) {
  return (
    <span
      className={`font-display text-2xl font-bold uppercase tracking-tight ${classes} ${variant === LogoVariant.LIGHT ? 'text-slate-900' : 'text-white'}`}
    >
      RegSage
    </span>
  );
}
