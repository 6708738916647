import {
  getFilterOptions,
  GetFilterOptionsResponse,
} from '@/api/notifications';
import { codeToName } from '@/lib/utils';
import { Filter } from '@/types/filter';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const FILTER_OPTIONS_QUERY_KEY = 'FILTER_OPTIONS';

export function useNotificationFilters({
  profileIds,
}: {
  profileIds: string[];
}) {
  const {
    data: filterOptions,
    error: filterError,
    isFetching: isFetchingFilters,
  } = useQuery<GetFilterOptionsResponse>({
    queryKey: [FILTER_OPTIONS_QUERY_KEY, profileIds],
    queryFn: () => getFilterOptions(profileIds),
    enabled: !!profileIds.length,
    refetchOnWindowFocus: false,
  });

  const convertFiltersToSelectFormat = (
    filters: GetFilterOptionsResponse | undefined,
  ): Filter[] => {
    if (!filters) {
      return [
        { id: 'jurisdiction', name: 'Jurisdiction', options: [] },
        { id: 'authority', name: 'Authority', options: [] },
        { id: 'regulation', name: 'Regulation', options: [] },
        { id: 'documentType', name: 'Document', options: [] },
        { id: 'topic', name: 'Topic', options: [] },
      ];
    }

    return [
      {
        id: 'jurisdiction',
        name: 'Jurisdiction',
        options:
          filters?.jurisdictions
            ?.map((jurisdiction) => ({
              value: JSON.stringify(jurisdiction),
              label:
                // TODO: find a better way to do this
                // if country no state
                jurisdiction.country && !jurisdiction.state
                  ? codeToName(jurisdiction.country) || jurisdiction.country
                  : jurisdiction.region
                    ? // if region
                      jurisdiction.region
                    : jurisdiction.state
                      ? // if state
                        jurisdiction.state
                      : '',
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'authority',
        name: 'Authority',
        options:
          filters.regulators
            ?.filter((regulator) => regulator.longName)
            ?.map((regulator) => ({
              value: JSON.stringify(regulator),
              label: regulator.longName as string,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'regulation',
        name: 'Regulation',
        options:
          filters.regulations
            ?.filter((regulation) => regulation.longName)
            ?.map((regulation) => ({
              value: JSON.stringify(regulation),
              label: regulation.longName as string,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'documentType',
        name: 'Document',
        options:
          filters.documentTypes
            ?.map((type) => ({
              value: type,
              label: type,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        id: 'topic',
        name: 'Topic',
        options:
          filters.topics
            ?.map((topic) => ({
              value: topic,
              label: topic,
              defaultChecked: false,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
    ];
  };

  const selectFilterOptions = useMemo(() => {
    return convertFiltersToSelectFormat(filterOptions);
  }, [filterOptions]);

  return {
    selectFilterOptions,
    filterError,
    isFetchingFilters,
  };
}
