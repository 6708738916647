import { handleGetSession } from '@/hooks/useAuth';
import { deepSnakeCaseTransform } from '@/api';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const partialFetch = (method: string) => {
  return async (url: string, data: any = {}, args: any = {}) => {
    const session = await handleGetSession();
    const token = session?.tokens?.idToken;
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...args.headers,
    });

    data = deepSnakeCaseTransform(data);
    const config = {
      method,
      headers,
      body: JSON.stringify(data),
      ...args,
    };
    return fetch(`${baseURL}${url}`, config);
  };
};

const streamApi = {
  get: partialFetch('GET'),
  post: partialFetch('POST'),
  put: partialFetch('PUT'),
  delete: partialFetch('DELETE'),
} as const;

export default streamApi;
