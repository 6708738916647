import { countryMap } from '@/consts/jurisdictions';
import { Filter, FilterKey } from '@/types/filter';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Converts a country code to a country name.
 * @param code - The country code (e.g., "US").
 * @returns The country name or undefined if the code is not found.
 */
export function codeToName(code: string): string | undefined {
  return countryMap[code];
}

/**
 * Converts a country name to a country code.
 * @param name - The country name (e.g., "United States").
 * @returns The country code or undefined if the name is not found.
 */
export function nameToCode(name: string): string | undefined {
  const entry = Object.entries(countryMap).find(([, value]) => value === name);
  return entry ? entry[0] : undefined;
}

export const getFilterById = (
  id: FilterKey,
  filters: Filter[],
): Filter | undefined => {
  return filters.find((filter) => filter.id === id);
};

export function getActiveFilter<T>(
  filterKey: FilterKey,
  filters: Filter[],
  parseValue: (value: string) => T,
): T[] {
  const filter = getFilterById(filterKey, filters);
  if (!filter) {
    return [];
  }

  return filter.options
    .filter((option) => option.checked)
    .map((option) => parseValue(option.value)) as T[];
}
