export default function List<T>({
  items,
  renderItem,
  emptyMessage,
  classes,
}: {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  emptyMessage: React.ReactNode;
  classes?: Record<string, string>;
}) {
  if (items.length === 0) {
    return <div>{emptyMessage}</div>;
  }

  return (
    <ul className={`divide-y divide-gray-100 ${classes?.ul}`}>
      {items.map((item, index) => (
        <li
          key={index}
          className={`flex w-full flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap ${classes?.li}`}
        >
          {renderItem(item)}
        </li>
      ))}
    </ul>
  );
}
