import { Jurisdiction, Regulation, Regulator } from '@/types';
import { SearchResult } from '@/types/search';
import api from './index';

export type SearchResponse = {
  results: SearchResult[];
  total: number;
};

export type SortField = 'date';
export type SortDirection = 'asc' | 'desc';

export interface SortOrder {
  field: SortField;
  direction: SortDirection;
}

export type SearchRequest = {
  query: string;
  dateRange?: [string | null, string | null];
  sort?: SortOrder;
  jurisdiction?: Jurisdiction[];
  regulator?: Regulator[];
  regulation?: Regulation[];
  documentType?: string[];
  topic?: string[];
};

export type SearchParams = {
  page: number;
  pageSize: number;
} & SearchRequest;

export const searchArticles = async ({
  query,
  page,
  pageSize,
  ...filters
}: SearchParams): Promise<SearchResponse> => {
  const response = await api.post(
    `/search?page=${page}&page_size=${pageSize}`,
    { query, ...filters },
  );
  return response.data;
};
