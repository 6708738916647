import '@/App.css';
import { navigationMap } from '@/consts/navigation';
import useSignUpListener from '@/hooks/useSignupListener';
import RadarPage from '@/pages/RadarPage';
import { UserProvider } from '@/providers/userProvider';
import { useEffect } from 'react';
import {
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import RequiresAuth from './components/Routes/RequiresAuth';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import LoginPage from './pages/Auth/LoginPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage';
import SignUpPage from './pages/Auth/SignupPage';
import LibraryPage from './pages/LibraryPage';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import { AuthProvider } from './providers/authProvider';
import ProfilesProvider from './providers/profilesProvider';
import { RagFiltersProvider } from './providers/ragFiltersProvider';
import TrackingProvider from './providers/trackingProvider';

export default function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/668bc879e1e4f70f24eec773/1i290e74k';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when component unmounts
      document.body.removeChild(script);
    };
  }, []);
  useSignUpListener();

  return (
    <Router>
      <AuthProvider>
        <TrackingProvider>
          <UserProvider>
            <ProfilesProvider>
              <RagFiltersProvider>
                <Routes>
                  {/* Public routes */}
                  <Route
                    path={navigationMap.login.href}
                    element={<LoginPage />}
                  />
                  <Route
                    path={navigationMap.forgotPassword.href}
                    element={<ForgotPasswordPage />}
                  />
                  <Route
                    path={navigationMap.resetPassword.href}
                    element={<ResetPasswordPage />}
                  />
                  <Route
                    path={navigationMap.register.href}
                    element={<SignUpPage />}
                  />

                  {/* Private routes */}
                  <Route
                    path={navigationMap.radar.href}
                    element={
                      <RequiresAuth>
                        <RadarPage />
                      </RequiresAuth>
                    }
                  />
                  <Route
                    path={`${navigationMap.radar.href}/:notificationId`}
                    element={
                      <RequiresAuth>
                        <RadarPage />
                      </RequiresAuth>
                    }
                  />
                  <Route
                    path={navigationMap.search.href}
                    element={
                      <RequiresAuth>
                        <SearchPage />
                      </RequiresAuth>
                    }
                  />
                  <Route
                    path={navigationMap.profile.href}
                    element={
                      <RequiresAuth>
                        <ProfilePage />
                      </RequiresAuth>
                    }
                  />
                  <Route
                    path={navigationMap.library.href}
                    element={
                      <RequiresAuth>
                        <LibraryPage />
                      </RequiresAuth>
                    }
                  />

                  {/* 404 route */}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </RagFiltersProvider>
            </ProfilesProvider>
          </UserProvider>
        </TrackingProvider>
      </AuthProvider>
    </Router>
  );
}

function NotFoundPage() {
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="mb-4 text-4xl font-semibold text-gray-900">404</h1>
        <h2 className="mb-6 text-xl font-medium text-gray-700">
          Nothing to see here!
        </h2>
        <p className="mb-8 text-gray-600">
          The page you are looking for might have been removed or is
          temporarily unavailable.
        </p>
        <Link
          to="/"
          className="text-lg font-medium text-blue-500 hover:text-blue-700"
        >
          Go to the home page
        </Link>
      </div>
    </div>
  );
}
