import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

interface Props {
  header: React.ReactNode;
  defaultOpen?: boolean;
  closedChildren?: React.ReactNode;
  children: React.ReactNode;
}

export default function Accordion({
  header,
  defaultOpen,
  closedChildren,
  children,
}: Props) {
  const renderMobileAccordion = () => {
    return (
      <Disclosure as="div" className="px-4" defaultOpen={defaultOpen}>
        {({ open }) => (
          <div className="py-2.5">
            <h3 className="-mx-2 -my-3 flow-root">
              <Disclosure.Button className="flex w-full items-start justify-between bg-white px-2 pb-3 pt-5 text-gray-400 hover:text-gray-500">
                {header}
                <span className="ml-6 flex items-center">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>
            {!open && closedChildren}
            <Disclosure.Panel className="py-4">{children}</Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  };

  const renderDesktopAccordion = () => {
    return (
      <Disclosure as="div" className="" defaultOpen={defaultOpen}>
        {({ open }) => (
          <div className="py-2.5">
            <h3 className="-my-3 flow-root">
              <Disclosure.Button className="flex w-full items-start justify-between bg-white pb-3 pt-5 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900">{header}</span>
                <span className="ml-6 flex items-center">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </h3>
            {!open && closedChildren}
            <Disclosure.Panel className="py-4">{children}</Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  };

  return (
    <>
      <div className="lg:hidden">{renderMobileAccordion()}</div>
      <div className="hidden lg:block">{renderDesktopAccordion()}</div>
    </>
  );
}
