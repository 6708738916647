import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface SelectDropdownMenuProps {
  label: React.ReactNode;
  children: React.ReactNode;
  buttonClassName?: string;
  itemsClassName?: string;
}

export default function SelectDropdownMenu({
  label,
  children,
  buttonClassName = '',
  itemsClassName = '',
}: SelectDropdownMenuProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={`group inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 ${buttonClassName}`}
      >
        {label}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${itemsClassName}`}
        >
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
