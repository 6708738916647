import { ArrowRightIcon } from '@heroicons/react/20/solid';
import React, { useEffect } from 'react';

interface Props {
  onSearch: (query: string) => void;
  initialQuery?: string;
}

const SearchBar: React.FC<Props> = ({ onSearch, initialQuery = '' }) => {
  const [query, setQuery] = React.useState(initialQuery);

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <form className="relative w-full" onSubmit={handleSubmit}>
      <div className="relative">
        <input
          type="text"
          className="sm:text-md block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-4 pl-4 pr-10 text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
          placeholder="What can I help you with?"
          style={{
            outline: 'none',
            borderColor: 'transparent !important',
          }}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        {query && query.trim() !== '' && (
          <button
            type="submit"
            className="group absolute right-2 top-2 rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
          >
            <ArrowRightIcon className="h-5 w-5 text-gray-400 transition-all duration-200 group-hover:translate-x-0.5 group-hover:text-gray-600" />
          </button>
        )}
      </div>
    </form>
  );
};

export default SearchBar;
