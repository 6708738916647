import Alert from '@/components/Alerts/Alert';
import SearchPalette from '@/components/Search/SearchPalette';
import SearchResults from '@/components/Search/SearchResults';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import { useRag } from '@/hooks/useRag';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { getActiveFilter } from '@/lib/utils';
import { useRagFilters } from '@/providers/ragFiltersProvider';
import { Filter } from '@/types/filter';
import { RagArticleResult } from '@/types/rag';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

export default function SearchPage() {
  usePageVisitTracker('Search');
  const [selectedResult, setSelectedResult] =
    useState<RagArticleResult | null>(null);
  const [searchFilters, setSearchFilters] = useState<Filter[]>([]);
  const [query, setQuery] = useState<string>('');
  const [hasQuery, setHasQuery] = useState<boolean>(false);
  const { performQuery, results, summary, isLoading, emptyState, error } =
    useRag();
  const { selectFilterOptions, isFetchingFilters } = useRagFilters();

  useEffect(() => {
    setSearchFilters(selectFilterOptions);
  }, [selectFilterOptions]);

  const onSearch = async (query: string) => {
    if (!query || query === '') {
      setHasQuery(false);
      return;
    }

    setHasQuery(true);
    setQuery(query);

    await performQuery({
      query,
      limit: 10,
      offset: 0,
      filters: {
        jurisdiction: getActiveFilter('jurisdiction', searchFilters, (value) =>
          JSON.parse(value),
        ),
        regulator: getActiveFilter('authority', searchFilters, (value) =>
          JSON.parse(value),
        ),
        regulation: getActiveFilter('regulation', searchFilters, (value) =>
          JSON.parse(value),
        ),
        documentType: getActiveFilter(
          'documentType',
          searchFilters,
          (value) => value,
        ),
        topic: getActiveFilter('topic', searchFilters, (value) => value),
        dateAfter: null,
        dateBefore: null,
      },
    });

    setSelectedResult(null);
  };

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        {/* Main content area with transitions */}
        <div className="h-full flex-1 overflow-hidden">
          <div className="relative h-full">
            <div className="h-full flex-1 overflow-hidden">
              <Transition
                show={!hasQuery}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="relative"
              >
                <div className="absolute top-0 flex w-full justify-center pt-10">
                  <div className="max-w-7xl">
                    <Alert type="info" title="Supported Jurisdictions">
                      Search is currently supported for United Kingdom,
                      Ireland, Austria, European Union, Canada, Brazil,
                      Argentina, Australia. Please contact us and we will
                      onboard a new jurisdiction within 72 hours for you.
                    </Alert>
                  </div>
                </div>
                <div className="flex min-h-[600px] flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
                  <SearchPalette
                    onSearch={onSearch}
                    searchFilters={searchFilters}
                    setSearchFilters={setSearchFilters}
                    isFetchingFilters={isFetchingFilters}
                  />
                </div>
              </Transition>

              <Transition
                show={hasQuery}
                enter="transition-opacity duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="h-full"
              >
                <div className="relative h-full">
                  <button
                    className="absolute top-1 z-50 cursor-pointer rounded-md border-0 bg-gray-100 p-2 transition-colors hover:bg-gray-200 focus:ring-0"
                    onClick={() => {
                      setHasQuery(false);
                      setSelectedResult(null);
                    }}
                  >
                    <ArrowLeftIcon className="h-6 w-6" />
                  </button>
                  <SearchResults
                    results={results}
                    summary={summary}
                    selectedResult={selectedResult}
                    setSelectedResult={setSelectedResult}
                    isLoading={isLoading}
                    emptyState={emptyState}
                    error={error}
                    query={query}
                    onSearch={onSearch}
                    searchFilters={searchFilters}
                    setSearchFilters={setSearchFilters}
                    isFetchingFilters={isFetchingFilters}
                  />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
