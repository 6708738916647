import React from 'react';

interface RiskScoreProps {
  score: number;
  decimals?: number;
  className?: string;
}

const RiskScore: React.FC<RiskScoreProps> = ({
  score,
  decimals = 0,
  className = '',
}) => {
  let colorClass = 'text-green-500 bg-green-50 ring-green-500/30';

  if (score >= 70) {
    colorClass = 'text-red-500 bg-red-50 ring-red-500/30';
  } else if (score >= 30) {
    colorClass = 'text-orange-500 bg-orange-50 ring-orange-500/30';
  }

  return (
    <span
      className={`inline-flex h-10 w-10 items-center justify-center rounded-full text-sm font-semibold ring-1 ring-inset ${colorClass} ${className}`}
    >
      {score.toFixed(decimals)}
    </span>
  );
};

export default RiskScore;
