import { checkUrl } from '@/api/checkUrl';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'react-toastify';

interface ValidUrlProps {
  url: string;
  children: React.ReactNode;
}

function ValidUrl({ url, children }: ValidUrlProps) {
  const { refetch, isFetching } = useQuery({
    queryKey: ['checkUrl', url],
    queryFn: () => checkUrl(url),
    enabled: false, // Only fetch when triggered
    retry: false, // No retries to avoid unnecessary requests
  });

  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();

    toast.loading(
      <div className="p-2">
        <p className="text-base font-semibold leading-6 text-gray-600">{`Checking link...`}</p>
      </div>,
      {
        toastId: 'urlCheck',
      },
    );

    try {
      const { data } = await refetch();

      if (data?.isValid) {
        toast.update('urlCheck', {
          render: (
            <div className="p-2">
              <p className="text-base font-semibold leading-6 text-gray-600">{`Opening link...`}</p>
            </div>
          ),
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        toast.update('urlCheck', {
          render: (
            <div className="p-2">
              <p className="text-base font-semibold leading-6 text-gray-600">{`The link has been moved by the publisher`}</p>
              <p className="py-2 text-sm">{`Please see the source text below`}</p>
            </div>
          ),
          type: 'error',
          isLoading: false,
          autoClose: 8000,
        });
      }
    } catch (error) {
      toast.update('urlCheck', {
        render: (
          <div className="p-2">
            <p className="text-base font-semibold leading-6 text-gray-600">{`Opening link...`}</p>
          </div>
        ),
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <a
      href={url}
      onClick={handleClick}
      style={{ pointerEvents: isFetching ? 'none' : 'auto' }}
    >
      {children}
    </a>
  );
}

export default ValidUrl;
