import ContentLoader from 'react-content-loader';

export const RagSourceSkeleton = (props: any) => (
  <ContentLoader
    speed={1.5}
    height={120}
    className="py-3"
    backgroundColor="#e3e3e3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="140" height="12" />
    <rect x="0" y="20" rx="3" ry="3" width="160" height="12" />
    <rect x="0" y="64" rx="3" ry="3" width="90" height="8" />
    <rect x="0" y="84" rx="3" ry="3" width="110" height="8" />
  </ContentLoader>
);

export const RagSummarySkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={480}
    className="mt-3 p-1"
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="0" y="20" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="40" rx="3" ry="3" width="98%" height="8" />
    <rect x="0" y="60" rx="3" ry="3" width="92%" height="8" />
    <rect x="0" y="80" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="100" rx="3" ry="3" width="90%" height="8" />
    <rect x="0" y="120" rx="3" ry="3" width="70%" height="8" />
    <rect x="0" y="180" rx="3" ry="3" width="80%" height="8" />
    <rect x="0" y="200" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="240" rx="3" ry="3" width="75%" height="8" />
    <rect x="0" y="260" rx="3" ry="3" width="80%" height="8" />
    <rect x="0" y="300" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="340" rx="3" ry="3" width="88%" height="8" />
    <rect x="0" y="360" rx="3" ry="3" width="80%" height="8" />
    <rect x="0" y="400" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="420" rx="3" ry="3" width="70%" height="8" />
    <rect x="0" y="440" rx="3" ry="3" width="75%" height="8" />
  </ContentLoader>
);

export const RagSummaryBulletsSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={240}
    className="mt-3 p-1"
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="20" rx="3" ry="3" width="98%" height="8" />
    <rect x="0" y="60" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="80" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="100" rx="3" ry="3" width="90%" height="8" />
    <rect x="0" y="120" rx="3" ry="3" width="92%" height="8" />
    <rect x="0" y="160" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="180" rx="3" ry="3" width="98%" height="8" />
    <rect x="0" y="200" rx="3" ry="3" width="75%" height="8" />
    <rect x="0" y="220" rx="3" ry="3" width="85%" height="8" />
  </ContentLoader>
);

export const ArticleContentSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={600}
    className="mt-3 p-1"
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    {[...Array(30)].map((_, index) => (
      <rect
        key={index}
        x="0"
        y={`${index * 20}`}
        rx="3"
        ry="3"
        width={`${Math.floor(Math.random() * 30) + 70}%`}
        height="8"
      />
    ))}
  </ContentLoader>
);
