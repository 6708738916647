import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import React, { ReactNode } from 'react';

interface Action {
  label: string;
  onClick: () => void;
}

interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  children: ReactNode;
  actions?: Action[];
}

const iconMap = {
  success: CheckCircleIcon,
  error: XCircleIcon,
  warning: ExclamationTriangleIcon,
  info: InformationCircleIcon,
};

const bgColorMap = {
  success: 'bg-green-50',
  error: 'bg-red-50',
  warning: 'bg-yellow-50',
  info: 'bg-blue-50',
};

const textColorMap = {
  success: 'text-green-800',
  error: 'text-red-800',
  warning: 'text-yellow-700',
  info: 'text-blue-700',
};

const contentColorMap = {
  success: 'text-green-400',
  error: 'text-red-400',
  warning: 'text-yellow-400',
  info: 'text-blue-400',
};

const Alert: React.FC<AlertProps> = ({ type, title, children, actions }) => {
  const Icon = iconMap[type];
  const bgColor = bgColorMap[type];
  const textColor = textColorMap[type];
  const contentColor = contentColorMap[type];

  return (
    <div className={`rounded-md p-4 ${bgColor}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon aria-hidden="true" className={`h-5 w-5 ${contentColor}`} />
        </div>
        <div className="ml-3 flex-1">
          <div className="flex justify-between">
            <h3 className={`text-sm font-medium ${textColor}`}>{title}</h3>
          </div>
          <div className={`mt-2 text-sm ${textColor}`}>{children}</div>
          {actions && (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                {actions.map((action, index) => (
                  <button
                    key={index}
                    type="button"
                    className="ml-3 rounded-md bg-white px-2 py-1.5 text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={action.onClick}
                  >
                    {action.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
