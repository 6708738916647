import { UserIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface ProfileAvatarProps {
  name?: string;
  size?: 'xs' | 'sm' | 'md' | 'base' | 'lg';
  className?: string;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  name,
  size = 'md',
  className = '',
}) => {
  const sizeClasses = {
    xs: 'h-4 w-4 text-[0.5rem]',
    sm: 'h-5 w-5 text-[0.625rem]',
    md: 'h-6 w-6 text-sm',
    base: 'h-8 w-8 text-base',
    lg: 'h-10 w-10 text-lg',
  };

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map((n) => n[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  };

  const AVATAR_COLORS = [
    '#4BADE8', // Blue
    '#65BA43', // Green
    '#E94D3C', // Red
    '#F7B53F', // Yellow
    '#9C5BB5', // Purple
    '#42526E', // Navy
    '#63BAB7', // Teal
    '#F15B50', // Coral
    '#00A3BF', // Cyan
    '#36B37E', // Emerald
    '#FF5630', // Orange Red
    '#998DD9', // Lavender
    '#2684FF', // Royal Blue
    '#57D9A3', // Mint
    '#FF7452', // Salmon
    '#8777D9', // Periwinkle
    '#00C7E6', // Bright Cyan
    '#4C9AFF', // Light Blue
    '#79F2C0', // Sea Green
    '#C054BE', // Magenta
    '#E774BB', // Pink
    '#B8ACF6', // Light Purple
    '#00B8D9', // Ocean Blue
    '#36B37E', // Forest Green
  ];

  const getColorClass = (name: string | undefined) => {
    if (!name) return '#DDDDDD'; // Default gray for no name
    const hash = name
      .split('')
      .reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    return AVATAR_COLORS[Math.abs(hash) % AVATAR_COLORS.length];
  };

  return (
    <div
      className={`flex aspect-square items-center justify-center rounded-full text-white ${sizeClasses[size]} ${className}`}
      style={{ backgroundColor: getColorClass(name) }}
    >
      {name ? (
        getInitials(name)
      ) : (
        <UserIcon className={`${sizeClasses[size]}`} />
      )}
    </div>
  );
};

export default ProfileAvatar;
