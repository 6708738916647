import * as Scroll from '@radix-ui/react-scroll-area';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useRef } from 'react';

interface VirtualizedListProps {
  items: React.ReactNode[];
  itemHeight?: number;
  height: number;
}

const VirtualizedList: React.FC<VirtualizedListProps> = ({
  items,
  itemHeight = 35, // Default item height
  height = 100,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => {
      console.log('getScrollElement', parentRef.current);
      return parentRef.current;
    },
    estimateSize: () => itemHeight,
    overscan: 10,
  });

  if (items.length === 0) return null;

  return (
    <Scroll.Root className="h-full w-full overflow-hidden rounded">
      <Scroll.Viewport
        ref={parentRef}
        className={`h-full w-full rounded min-h-[${height}px]`}
      >
        <div className="relative w-full">
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const item = items[virtualItem.index];
            return (
              <div
                key={virtualItem.index}
                className="absolute left-0 top-0 w-full"
                style={{ transform: `translateY(${virtualItem.start}px)` }}
              >
                {item}
              </div>
            );
          })}
        </div>
      </Scroll.Viewport>
      <Scroll.Scrollbar
        className="flex touch-none select-none bg-gray-200/10 p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col hover:bg-gray-200"
        orientation="vertical"
      >
        <Scroll.Thumb className="relative flex-1 rounded-[10px] bg-gray-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
      </Scroll.Scrollbar>
      <Scroll.Corner className="bg-black" />
    </Scroll.Root>
  );
};

export default VirtualizedList;
