import { UserContextType } from '@/contexts';
import useAuth from '@/hooks/useAuth';
import { User } from '@/types/users';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export const USER_KEY = 'USER';

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const { getCurrentUser } = useAuth();

  const setUserInStorage = useCallback((newUser: User | null) => {
    if (newUser) {
      localStorage.setItem(USER_KEY, JSON.stringify(newUser));
    } else {
      localStorage.removeItem(USER_KEY);
    }
    setUser(newUser);
  }, []);

  // Initial user loading
  useEffect(() => {
    const loadUser = async () => {
      try {
        const savedUser = localStorage.getItem(USER_KEY);
        if (savedUser) {
          setUser(JSON.parse(savedUser));
        } else {
          const currentUser = await getCurrentUser();
          setUserInStorage(currentUser || null);
        }
      } catch (error) {
        console.error('Error loading user:', error);
        setUserInStorage(null);
      }
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle user updates
  const updateUser = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      setUserInStorage(currentUser || null);
    } catch (error) {
      console.error('Error updating user:', error);
      setUserInStorage(null);
    }
  }, [getCurrentUser, setUserInStorage]);

  return (
    <UserContext.Provider value={{ user, setUser: updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export default UserProvider;
