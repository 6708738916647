import ChatFeed from '@/components/Chat/ChatFeed';
import DocumentAsMarkdown from '@/components/DocumentAsMarkdown';
import List from '@/components/List/List';
import ScrollContainer from '@/components/List/ScrollContainer';
import NotificationItem from '@/components/Notification/NotificationItem';
import ValidUrl from '@/components/ValidUrl';
import SplitPanel from '@/layouts/SplitPanel';
import { NotificationResult } from '@/types/notification';
import { LinkIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { InboxIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useRef, useState } from 'react';

const NoNotifications = memo(() => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <InboxIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-lg font-medium">{`No notifications found`}</p>
    <p className="text-md mt-2">
      {'Please try updating your filters or check back later for updates'}
    </p>
  </div>
));
NoNotifications.displayName = 'NoNotifications';

const NotificationResults = memo(
  ({
    view,
    notifications,
    selectedNotification,
    setSelectedNotification,
    setSourceId,
    selectedRef,
  }: {
    view: 'narrow' | 'wide';
    notifications: NotificationResult[];
    selectedNotification: NotificationResult | null;
    setSelectedNotification: (notification: NotificationResult | null) => void;
    setSourceId: (sourceId: number | null) => void;
    selectedRef: React.RefObject<HTMLDivElement>;
  }) => {
    return (
      <ScrollContainer>
        <List
          items={notifications}
          emptyMessage={<NoNotifications />}
          renderItem={(notification) => (
            <div
              ref={notification === selectedNotification ? selectedRef : null}
              className="w-full"
            >
              <NotificationItem
                notification={notification}
                selectedNotification={selectedNotification}
                setSelectedNotification={setSelectedNotification}
                setSourceId={setSourceId}
                view={view}
              />
            </div>
          )}
          classes={{
            li: `hover:bg-gray-50 py-2 ${view === 'narrow' ? 'px-2' : 'px-6'}`,
          }}
        />
      </ScrollContainer>
    );
  },
);
NotificationResults.displayName = 'NotificationResults';

export default function NotificationList({
  notifications,
  selectedNotification,
  setSelectedNotification,
}: {
  notifications: NotificationResult[];
  selectedNotification: NotificationResult | null;
  setSelectedNotification: (notification: NotificationResult | null) => void;
}) {
  const [sourceId, setSourceId] = useState<number | null>(null);
  const selectedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedNotification && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedNotification]);

  return (
    <div className="h-full w-full flex-col">
      {selectedNotification ? (
        <SplitPanel
          leftContent={
            <div className="flex h-full flex-col overflow-hidden">
              <ChatFeed
                header={
                  <NotificationItem
                    notification={selectedNotification}
                    selectedNotification={selectedNotification}
                    setSelectedNotification={setSelectedNotification}
                    setSourceId={setSourceId}
                    view={'narrow'}
                  />
                }
                article={selectedNotification.doc}
              />
            </div>
          }
          rightContent={
            <div className="flex h-full flex-col border-l border-gray-200">
              <div className="flex-shrink-0 px-4 py-4 shadow-sm">
                <div className="flex items-center justify-between">
                  <ValidUrl url={selectedNotification?.url}>
                    <div className="flex items-center text-blue-500 underline hover:text-blue-600">
                      <div className="text-sm font-medium">
                        {selectedNotification?.title}
                      </div>
                      <LinkIcon
                        className="ml-1.5 h-4 w-4 flex-shrink-0 cursor-pointer text-gray-500 hover:text-blue-500"
                        aria-hidden="true"
                      />
                    </div>
                  </ValidUrl>
                  <button
                    onClick={() => {
                      setSelectedNotification(null);
                      setSourceId(null);
                    }}
                    className="pl-4 text-gray-500 hover:text-gray-700"
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="flex-grow overflow-hidden">
                <ScrollContainer>
                  <div className="overflow-y-auto">
                    {selectedNotification.doc ? (
                      <DocumentAsMarkdown
                        doc={selectedNotification.doc}
                        highlightIndex={sourceId}
                      />
                    ) : (
                      <div className="flex-col p-8 text-center text-sm text-gray-800">
                        <div className="">
                          The document is not available for preview
                        </div>
                        <div>
                          Please view the original document{' '}
                          <ValidUrl url={selectedNotification.url}>
                            <p className="text-blue-500 hover:underline">
                              here
                            </p>
                          </ValidUrl>
                        </div>
                      </div>
                    )}
                  </div>
                </ScrollContainer>
              </div>
            </div>
          }
        />
      ) : (
        <NotificationResults
          view={'wide'}
          notifications={notifications}
          selectedNotification={selectedNotification}
          setSelectedNotification={setSelectedNotification}
          setSourceId={setSourceId}
          selectedRef={selectedRef}
        />
      )}
    </div>
  );
}
