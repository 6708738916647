import { Link } from 'react-router-dom';

import { LogoSize, logoSizeMap, LogoVariant } from '@/types';

import BrandText from './BrandText';
import { Logo } from './Logo';

const renderLogoAndText = (variant: LogoVariant, size: LogoSize) => (
  <div className="flex items-center py-2">
    <Logo classes={`h-auto ${logoSizeMap[size].logo}`} variant={variant} />
    <BrandText classes={`ml-2`} variant={variant} />
  </div>
);

function LogoWithText({
  variant = LogoVariant.LIGHT,
  size = LogoSize.SMALL,
  href,
}: {
  variant?: LogoVariant;
  size?: LogoSize;
  href?: string;
}) {
  return href ? (
    <Link to={href} className="cursor-pointer hover:opacity-75">
      {renderLogoAndText(variant, size)}
    </Link>
  ) : (
    renderLogoAndText(variant, size)
  );
}

export default LogoWithText;
