import { CalendarIcon } from '@heroicons/react/20/solid';
import React from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

type Shortcut = {
  text: string;
  period: {
    start: Date;
    end: Date;
  };
};

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  shortcuts?: Record<string, Shortcut>;
  inputClassName?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  shortcuts = {},
  inputClassName = 'w-[280px] text-sm text-gray-700 rounded-full bg-gray-100 pl-10 pr-3 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors',
}) => {
  const getMaxDate = () => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  };
  const getMinDate = () => {
    const date = new Date('2000-01-01');
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const formatDisplayDate = (date: Date | null) => {
    return date
      ? new Date(date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        })
      : 'DD/MM/YY';
  };

  return (
    <div className="relative flex gap-2">
      <div className="relative">
        <CalendarIcon className="absolute left-3 top-1/2 z-10 h-5 w-5 -translate-y-1/2 text-gray-400" />
        <Datepicker
          primaryColor="blue"
          startFrom={startDate}
          onChange={(value: DateValueType) => {
            if (!value || !value.startDate || !value.endDate) {
              return;
            }
            if (value.startDate < getMinDate()) {
              value.startDate = getMinDate();
            }
            if (value.startDate > getMaxDate()) {
              value.startDate = getMaxDate();
            }
            setStartDate(value?.startDate as Date);
            setEndDate(value?.endDate as Date);
          }}
          value={{ startDate, endDate }}
          inputClassName={inputClassName}
          containerClassName="relative w-full"
          popoverDirection="down"
          showShortcuts
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          displayFormat={'DD/MM/YY'}
          configs={{
            shortcuts: {
              last30Days: {
                text: 'Last 30 days',
                period: {
                  start: new Date(
                    new Date().setDate(new Date().getDate() - 30),
                  ),
                  end: new Date(new Date().setDate(new Date().getDate() - 1)),
                },
              },
              last90Days: {
                text: 'Last 90 days',
                period: {
                  start: new Date(
                    new Date().setDate(new Date().getDate() - 90),
                  ),
                  end: new Date(new Date().setDate(new Date().getDate() - 1)),
                },
              },
              lastYear: {
                text: 'Last year',
                period: {
                  start: new Date(
                    new Date().setFullYear(new Date().getFullYear() - 1),
                  ),
                  end: new Date(new Date().setDate(new Date().getDate() - 1)),
                },
              },
              ...shortcuts,
            },
          }}
          useRange={true}
          asSingle={false}
          placeholder={`${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`}
          readOnly={true}
          toggleClassName="hidden"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
