import * as Scroll from '@radix-ui/react-scroll-area';

interface ScrollAreaProps {
  children: React.ReactNode;
}

export default function ScrollArea({ children }: ScrollAreaProps) {
  return (
    <Scroll.Root className={`h-full w-full overflow-hidden rounded`}>
      <Scroll.Viewport className="h-full w-full rounded">
        {children}
      </Scroll.Viewport>
      <Scroll.Scrollbar
        className="flex touch-none select-none bg-gray-200/10 p-0.5 transition-colors ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col hover:bg-gray-200"
        orientation="vertical"
      >
        <Scroll.Thumb className="relative flex-1 rounded-[10px] bg-gray-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
      </Scroll.Scrollbar>
      <Scroll.Corner className="bg-black" />
    </Scroll.Root>
  );
}
