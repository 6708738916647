import { Jurisdiction, Regulation, Regulator } from '@/types';
import { NotificationResult } from '@/types/notification';
import api from './index';

export interface GetNotificationsResponse {
  notifications: NotificationResult[];
  total: number;
}

export interface GetFilterOptionsResponse {
  jurisdictions: Jurisdiction[];
  regulators: Regulator[];
  regulations: Regulation[];
  documentTypes: string[];
  topics: string[];
}

export type SortField = 'date' | 'risk';
export type SortDirection = 'asc' | 'desc';

export interface SortOrder {
  field: SortField;
  direction: SortDirection;
}

export type NotificationsRequest = {
  profileIds: string[];
  dateRange?: [string, string];
  sort?: SortOrder;
  keywords?: string[];
  documentType?: string[];
  regulator?: Regulator[];
  regulation?: Regulation[];
  jurisdiction?: Jurisdiction[];
  topic?: string[];
};

export type GetNotificationsParams = {
  page: number;
  pageSize: number;
} & NotificationsRequest;

export const getNotifications = async ({
  profileIds,
  page,
  pageSize,
  dateRange,
  sort,
  keywords,
  documentType,
  regulator,
  regulation,
  jurisdiction,
  topic,
}: GetNotificationsParams): Promise<GetNotificationsResponse> => {
  const request: NotificationsRequest = {
    profileIds: profileIds,
    dateRange: dateRange,
    sort: sort
      ? {
          field: sort.field,
          direction: sort.direction,
        }
      : undefined,
    keywords: keywords,
    documentType: documentType,
    regulator: regulator,
    regulation: regulation,
    jurisdiction: jurisdiction,
    topic: topic,
  };

  const response = await api.post(
    `notifications?page=${page}&page_size=${pageSize}`,
    request,
  );
  return response.data;
};

export const getNotificationById = async (
  id: string,
): Promise<NotificationResult> => {
  const response = await api.get(`notifications/${id}`);
  return response.data;
};

export async function getFilterOptions(
  profileIds: string[],
): Promise<GetFilterOptionsResponse> {
  const response = await api.post('notifications/filters', {
    profile_ids: profileIds,
  });
  return response.data;
}
